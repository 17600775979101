import React from 'react';
import { PulseLoader } from 'react-spinners';
import { Redirect } from 'react-router-dom';
import './appointment_options.scss';
import moment from 'moment';
import { Spacer, Button as ContinueButton } from '../../styles/styledComponents';
import { withTranslation } from 'react-i18next';

class AppointmentOptionsOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loadingTimes: false,
      hover: JSON.parse(window.sessionStorage.phoneType || true),
      appointments: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleAppointment = this.handleAppointment.bind(this);
    this.handleProvider = this.handleProvider.bind(this);
  }

  scrollToTop() {
    const scrolly = document.getElementsByClassName('div2');
    if (JSON.parse(window.sessionStorage.phoneType)) scrolly[0].scrollIntoView(true);
  }

  componentDidMount() {
    this.scrollToTop();

    const currentPage = window.sessionStorage.getItem('currentPage');
    this.setState({ loadingTimes: true });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem('phoneType', true);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem('phoneType', false);
      this.setState({ hover: true });
    }

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 5) {
        window.sessionStorage.setItem('currentPage', 6);
        window.sessionStorage.setItem('backPage', 5);
        this.getData();
      } else {
        this.props.history.push(
          `/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ''
          }`,
        );
      }
    } else {
      if (parseInt(currentPage) === 5 || parseInt(currentPage) === 6) {
        window.sessionStorage.setItem('currentPage', 6);
        this.getData();
      } else {
        if (
          window.securedNav === true &&
          (parseInt(currentPage) === 7 || parseInt(currentPage) === 9)
        ) {
          window.sessionStorage.setItem('currentPage', 6);
          window.securedNav = false;
          this.getData();
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.replace(
              `/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) - 1
              }`,
            );
          } else {
            this.props.history.replace(
              `/${this.props.match.params.id}/${parseInt(currentPage) - 1}`,
            );
          }

          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(
              `/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ''
              }`,
            );
          } else {
            this.props.history.push(
              `/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ''
              }`,
            );
          }
        }
      }
    }
  }

  getData() {
    const dow = JSON.parse(window.sessionStorage.getItem('dow'));
    const patientPrefs = JSON.parse(window.sessionStorage.getItem('patientPrefs'));
    let data;

    let providerId = window.sessionStorage.getItem('providerId');

    let providerIdx = this.props.config.providers.findIndex(
      (providers) => parseInt(providerId) === parseInt(providers.provider_assoc),
    );
    let foundProvider = this.props.config.providers[providerIdx];

    if (foundProvider) {
      providerId = foundProvider.provider_id;
    }

    if (window.sessionStorage.getItem('locationId')) {
      data = {
        client: window.sessionStorage.getItem('locationId'),
        dow: dow,
        appointmentId: window.sessionStorage.getItem('appointmentId'),
        providerId: providerId,
        patientPrefs: patientPrefs,
      };
    } else {
      data = {
        client: this.props.match.params.id,
        dow: dow,
        appointmentId: window.sessionStorage.getItem('appointmentId'),
        providerId: providerId,
        patientPrefs: patientPrefs,
      };
    }

    this.props.getAppointments(data).then(() => {
      let appointments = this.props.appointments || [];

      if (appointments === 'NA' || appointments === 400 || appointments === 'none') {
        appointments = [];
      } else {
        appointments = [...appointments].reverse();
      }

      this.setState({
        loadingTimes: false,
        loaded: true,
        appointments: appointments,
      });
    });
  }

  handleProvider(e) {
    e.preventDefault();
    window.sessionStorage.setItem('currentPage', 3);
    window.securedNav = true;
    this.props.history.push(`/${this.props.match.params.id}/3`);
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem('backPage', 6);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(
        `/${this.props.match.params.id}/${this.props.match.params.analytics}/7`,
      );
    } else {
      this.props.history.push(`/${this.props.match.params.id}/7`);
    }
  }

  handleAppointment = (day) => (e) => {
    e.preventDefault();
    window.sessionStorage.setItem('selectedAppointment', JSON.stringify(day));
    window.sessionStorage.setItem('currentPage', 8);
    window.sessionStorage.setItem('backPage', 6);
    window.sessionStorage.setItem('backButton', JSON.stringify(false));

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(
        `/${this.props.match.params.id}/${this.props.match.params.analytics}/9`,
      );
    } else {
      this.props.history.push(`/${this.props.match.params.id}/9`);
    }
  };

  render() {
    const { t } = this.props;
    const { loadingTimes, appointments, hover } = this.state;
    if (!this.state.loaded || loadingTimes || !appointments.length) {
      return (
        <div className="apptOptionsContainer">
          <div className="appointmentOptionsContainer">
            <div className="apptHeader">{t('Thanks')}!</div>

            <div className="apptDescription">{t("Here's what we have available")}:</div>

            <div className="row justify-content-center">
              {loadingTimes ? (
                <>
                  <Spacer height="10px" />
                  <PulseLoader color={'#707070'} size={10} />
                </>
              ) : (
                <div className="apptDescription">
                  {t('Sorry no appointments available with those times')}
                </div>
              )}
            </div>

            <ContinueButton
              color={this.props.config.color}
              hover={hover}
              onClick={this.handleClick}
              className="moreOptions col col-9 btn btn-primary"
            >
              {t('More Options')}
            </ContinueButton>
          </div>
        </div>
      );
    }

    if (appointments.length < 1) {
      window.sessionStorage.setItem('backPage', 5);
      let analyticsParam = '';
      if (this.props.match.params.analytics !== undefined) {
        analyticsParam = `/${this.props.match.params.analytics}`;
      }
      return <Redirect push to={`/${this.props.match.params.id}${analyticsParam}/7`} />;
    }
    let final = [];

    if (appointments !== 400 && appointments !== 'NA' && appointments !== undefined) {
      final = appointments;

      if (final.length > 0) {
        window.sessionStorage.setItem('optionsDow', final[0].dow);
      }
    }

    let changeProvider;
    let provs = [];

    let appointmentIndex;
    let appointment;

    if (this.props.config.appointments) {
      appointmentIndex = this.props.config.appointments.findIndex(
        (appointment) =>
          parseInt(appointment.appointment_id) ===
          parseInt(window.sessionStorage.getItem('appointmentId')),
      );
      appointment = this.props.config.appointments[appointmentIndex];
    }

    if (appointment) {
      provs = appointment.providers;

      if (provs.length < 2 || appointment.provider_select === 0) {
        changeProvider = <div></div>;
      } else {
        changeProvider = (
          <ContinueButton
            color={this.props.config.color}
            hover={hover}
            onClick={this.handleProvider}
            className="moreOptions col col-9 btn btn-primary"
          >
            {t('Change Provider')}
          </ContinueButton>
        );
      }
    }

    final = final.slice(0, 3);

    final = final.sort(function (a, b) {
      return a.date.localeCompare(b.date) || a.starttime.localeCompare(b.starttime);
    });

    return (
      <div
        className="apptOptionsContainer"
        ref={(el) => {
          this.el = el;
        }}
      >
        <div className="apptHeader">{t('Thanks')}!</div>

        <div className="apptDescription">{t("Here's what we have available")}:</div>

        <div className="row justify-content-center margin">
          {final.map((day, index) => {
            if (index >= 3) {
              return null;
            } else {
              return (
                <ContinueButton
                  color={this.props.config.color}
                  hover={hover}
                  key={index}
                  onClick={this.handleAppointment(day)}
                  className="col col-sm-11 col-md-9 appointmentTime"
                >
                  {t("{{date}}, {{month}} {{day}} at {{time}}", {
                    date: t(moment(day.date).format("dddd")),
                    month: t(moment(day.date).format("MMMM")),
                    day: moment(day.date).format("D"),
                    time: moment(day.starttime, "hh:mm:ss")
                      .format("h:mm a")
                      .replace(/(a|p)(m)/, "$1.$2."),
          })}
                </ContinueButton>
              );
            }
          })}

          <ContinueButton
            color={this.props.config.color}
            hover={hover}
            onClick={this.handleClick}
            className="appointmentTime col col-sm-11 col-md-9"
          >
            {t('Show More Options')}
          </ContinueButton>
        </div>
        {changeProvider}
      </div>
    );
  }
}

export default withTranslation()(AppointmentOptionsOne);

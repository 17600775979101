import React from 'react';
import styled from 'styled-components';
import "./providers.scss";
import providerLogo from '../../../images/providerImage.png';
import $ from "jquery";
import { getContrast } from '../../styles/contrast';

import FlexIcon from '../../styles/flex_icon';
import { withTranslation } from 'react-i18next';
import i18n from '../../../lib/i18n/index.js';

class Providers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      providers: [],
      loaded: false,
      hover: JSON.parse(window.sessionStorage.phoneType || true)
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleFindTime = this.handleFindTime.bind(this);
    this.handleChangeAppointment = this.handleChangeAppointment.bind(this);
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = "flex";

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem("phoneType", true);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem("phoneType", false);
      this.setState({ hover: true });
    }

    window.sessionStorage.setItem("providerId", 0);
    const currentPage = window.sessionStorage.currentPage;

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 2) {
        window.sessionStorage.setItem("currentPage", 3);
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if ((parseInt(currentPage) === 2 || parseInt(currentPage) === 3) && window.securedNav !== true) {
        window.sessionStorage.setItem("currentPage", 3);
      } else {
        if (window.securedNav === true && (parseInt(currentPage) === 4 || parseInt(currentPage) === 3)) {
          if (parseInt(currentPage) === 3) {
            window.sessionStorage.setItem("currentPage", 2);
          } else {
            window.sessionStorage.setItem("currentPage", 3);
          }

          window.securedNav = false;
        } else {
          if (this.props.match.params.analytics !== undefined) {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
            } else if (parseInt(currentPage) === 1) {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage)}`);
            } else {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) - 1}`);
            }
          } else {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/`);
            } else if (parseInt(currentPage) === 1) {
              this.props.history.replace(`/${this.props.match.params.id}/${parseInt(currentPage)}`);
            } else {
              this.props.history.replace(`/${this.props.match.params.id}/${parseInt(currentPage) - 1}`);
            }
          }
        }
      }
    }

    const div2 = document.getElementsByClassName("div2");
    $(div2).attr('style', 'overflow-y: scroll !important');


    let providers;
    let appointmentIndex;
    let appointment;

    if (this.props.config.providers) {
      providers = this.props.config.providers;
    }

    if (this.props.config.appointments) {
      appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
      appointment = this.props.config.appointments[appointmentIndex];
    }

    let finalProviders = [];

    if (providers && appointment) {
      providers.forEach(provider => {
        provider.allowed_days.forEach(day => {
          provider.operatories.forEach(op => {
            if (appointment.allowed_days.includes(day) && appointment.operatories.includes(op) && appointment.providers.includes(provider.provider_assoc.toString())) {
              finalProviders[provider.provider_assoc] = provider;
            }
          })
        });
      });

      finalProviders = finalProviders.filter(value => Object.keys(value).length !== 0);
    }

    if (appointment) {
      //if there's only one eligible provider, we treat this as if provider select is off
      if (finalProviders && finalProviders.length < 2) appointment.provider_select = 0
      if (parseInt(appointment.provider_select) === 0) {
        this.setState({
          providers: [],
          loaded: true
        })
      } else {
        this.setState({
          providers: finalProviders,
          loaded: true
        })
      }
    }
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem("providerId", e.currentTarget.dataset.provider);
    if (parseInt(e.currentTarget.dataset.provider) !== 0) {
      let providerIndex = this.props.config.providers.findIndex(provider => parseInt(provider.provider_assoc) === parseInt(window.sessionStorage.getItem("providerId")));
      window.sessionStorage.setItem("provider", JSON.stringify(this.props.config.providers[providerIndex]));
    }


    window.sessionStorage.setItem("currentPage", 3);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/4`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/4`);
    }
  }

  handleFindTime(e) {
    e.preventDefault();
    window.sessionStorage.setItem("providerId", 0);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/4`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/4`);
    }
  }

  handleChangeAppointment(e) {
    e.preventDefault();
    window.sessionStorage.setItem("currentPage", 1);

    window.securedNav = true;

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/2`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/2`);
    }
  }

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleClick(e);
    }
  };

  render() {
    const { t } = this.props;
    const currentLanguage = i18n.language;

    let contrastColor = this.props.config.color ? this.props.config.color : "#707070";

    if (this.props.config === 500 || this.props.config === 400 || !this.state.loaded) {
      return null;
    }

    const Div = styled.div`
      border: 1px solid #787878;
      background-color: #f5f5f5;
      border-color: #f5f5f5;
      color: #707070 !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }

      &:focus {
        border-color: #2699fb !important;
        outline: none !important;
      }
    `;

    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
    const appointment = this.props.config.appointments[appointmentIndex];

    const Button = styled.button`
      border: 1px solid !important;
      font-size: 16px !important;
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "#2699fb"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }

      &:focus {
        border: 2px solid #2699fb !important;
        outline: none !important;
      }
    `;

    let providersHeader;

    if (parseInt(appointment.provider_select) === 0) {
      providersHeader = <div>
        <div className="row justify-content-center">
          <div className="col col-sm-12 col-md-10 locationsDescription">
            {t("Tap below to view current openings or go back to edit your selection.")}
          </div>
        </div>

        <Button type="button" className="col col-10 btn btn-outline-primary findTimeButton" onClick={this.handleFindTime}>
          {t("Find a Date & Time")}
        </Button>

        <Button type="button" className="col col-10 btn btn-outline-primary changeAppointmentButton" onClick={this.handleChangeAppointment}>
          {t("Change Appointment Type")}
        </Button>

      </div>;

    } else {
      if (this.state.providers.length < 1) {
        providersHeader = <div>
          <div className="callUsHeader">
            {t("Call us!")}
          </div>
          <div className="row justify-content-center">
            <div className="col col-sm-12 col-md-10 locationsDescription">
              {t("It doesn't look like this appointment is currently eligible for online booking. Just give us a call!")}
            </div>
          </div>

          <div className="locationsNumber">
            {this.props.config.practicePhone}
          </div>
        </div>;
      } else {
        providersHeader = <div>
          <div className="providersHeader">
            {t("Next, please choose a provider:")}
          </div>
        </div>;
      }
    }

    return (
      <div className="provContainer">
        <div className="row justify-content-center">
          <div className="col col-12 provSelectHeader">
            {t("You've selected:")}
          </div>
          <div className="col col-10 patientTypeContainer">
            <div className="col-12 appointmentProviderContainer">
              <FlexIcon class="scheduleApptImage" icon={appointment.icon} />

              <div className="appointmentProviderType">
                {
                  currentLanguage === 'es' && appointment.name_translated ? appointment.name_translated : appointment.name
                }
              </div>

              <div className="appointmentTypeProviderTime">
                {appointment.length} {t("minutes")}
              </div>

              <div className="appointmentTypeDescription">
                {
                  currentLanguage === 'es' && appointment.description_translated ? appointment.description_translated : appointment.description
                }
              </div>
            </div>
          </div>
        </div>
        {providersHeader}

        <div className="row justify-content-center">

          {this.state.providers && this.state.providers.length > 1 ? (
            <Div tabIndex="1" onKeyPress={this._handleKeyPress} key={0} className="col-sm-10 col-md-5 providersContainer" data-provider="0" onClick={this.handleClick}>
              <div className="imageContainer col col-6 offset-3">
                <img className="providerImage" src={providerLogo} alt="no" />
              </div>
              <div className="providersName">
                {t("First available")}
              </div>
            </Div>
          )
            : null
          }

          {this.state.providers.map((provider, index) => {

            return (
              <Div onKeyPress={this._handleKeyPress} key={index + 1} tabIndex={index + 2} className="col-sm-10 col-md-5 providersContainer" data-provider={provider.provider_assoc} onClick={this.handleClick}>
                <div className="imageContainer col col-6 offset-3">
                  <img className="providerImage" src={!!provider.pictureURI ? provider.pictureURI : providerLogo} alt="no" />
                </div>

                <div className="providersName">
                  {provider.name}
                </div>
              </Div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default withTranslation()(Providers)

import i18n from '../lib/i18n/index.js';

const languageNames = {
  en: "English",
  es: "Spanish",
};

const getFullLanguageName = () => {
  const currentLanguageCode = i18n.language;
  return languageNames[currentLanguageCode] || "Unknown Language";
};

export { getFullLanguageName };
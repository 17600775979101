// react-redux
import React from 'react';
import ReactDOM from 'react-dom';
import Root from "./components/Root";
import configureStore from "./store/store";

// css
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';


document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener('popstate', (event) => {
    window.securedNav = true;
  });

  let store;

  if (window.sessionStorage.config && !window.sessionStorage.appointments) {
    const config = JSON.parse(window.sessionStorage.config);

    const preloadedState = {
      config: config
    };

    store = configureStore(preloadedState);

  } else if (window.sessionStorage.appointments) {
    let appointments;
    let config;

    if (window.sessionStorage.config) {
      config = JSON.parse(window.sessionStorage.config);
    } else {
      config = {};
    }

    if (typeof window.sessionStorage.appointments === "string") {
      appointments = window.sessionStorage.appointments;
    } else {
      appointments = JSON.parse(window.sessionStorage.appointments);
    }

    const preloadedState = {
      config: config,
      appointments: appointments
    }

    store = configureStore(preloadedState);

  } else {
    store = configureStore();
  }

  const root = document.getElementById("root");

  ReactDOM.render(<Root store={store} />, root);
});

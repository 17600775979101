import React from 'react';
import './schedule_appointment.scss';
import moment from 'moment';
import Select, { components } from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import Modal from './schedule_appointment_modal_container';
import Disclaimer from './disclaimer';
import $ from 'jquery';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import RadioQuestion from './inputs/radio';
import DropdownQuestion from './inputs/dropdown';
import TextQuestion from './inputs/text';
import FlexIcon from '../../styles/flex_icon';
import $$ from '../../../util/$$';
import GuarantorModal from './guarantorModal';
import {
  ScheduleButton as ContinueButton,
  SelectButton as Button,
  Spacer,
  BackButton,
} from '../../styles/styledComponents';
import { withTranslation } from 'react-i18next';
import i18n from '../../../lib/i18n/index.js';
import { getFullLanguageName } from '../../../util/languageUtils.js';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

const cookieExpiration = 34 * 60 * 1000;
class ScheduleAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.poyntCode = props.config.poyntCode;
    const payConfig = $$.keys.only(props.config, [
      'ccHoldEnabled',
      'ccHoldText',
      'ccHoldTextTranslated',
      'ccHoldAppointments',
      'ccDepositText',
      'ccDepositText',
      'ccDepositTextTranslated',
      'ccDepositEnabled',
      'ccDepositAmount',
      'ccDepositAppointments',
      'merchant',
      'paymentsActive',
    ]);
    let ccHandleMode;
    const appointmentId = window.sessionStorage.appointmentId;

    if (payConfig.merchant) {
      payConfig.paymentsActive = true;
      if (
        payConfig.ccHoldEnabled &&
        payConfig.ccHoldAppointments &&
        payConfig.ccHoldAppointments.includes(appointmentId)
      ) {
        ccHandleMode = 'hold';
      }
      // If the appt type is flagged for deposits, we'll go w/ that
      if (
        payConfig.ccDepositEnabled &&
        payConfig.ccDepositAppointments &&
        payConfig.ccDepositAppointments.includes(appointmentId)
      ) {
        ccHandleMode = 'deposit';
      }
    }

    this.state = {
      fname: '',
      lname: '',
      dob: '',
      email: '',
      phone: '',
      hasInsurance: '',
      insurance: '',
      insuranceOther: 'No',
      selectedOption: null,
      showInsuranceSelection: false,
      showInsuranceMessage: false,
      showNoInsuranceMessage: false,
      show: false,
      insurance_change: false,
      showCodeAlert: 'show',
      subscriber_id: '',
      loading: false,
      returning: window.sessionStorage.patientType,
      hover: JSON.parse(window.sessionStorage.phoneType || true),
      ccAgree: false,
      moolahNonceComplete: false,
      ccHandleMode,
      ccApptIncluded:
        (payConfig.ccHoldEnabled &&
          payConfig.ccHoldAppointments?.includes(appointmentId)) ||
        (payConfig.ccDepositEnabled &&
          payConfig.ccDepositAppointments?.includes(appointmentId)),
      ...payConfig,
      showGuarantorModal: !!props.config.checkForGuarantor,
      scheduleFor: null,
      guarantor: null,
      dobAlert: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMoolahSubmit = this.handleMoolahSubmit.bind(this);
    this.handleDob = this.handleDob.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleInsurance = this.handleInsurance.bind(this);
    this.updateInsurance = this.updateInsurance.bind(this);
    this.updateReturningInsurance = this.updateReturningInsurance.bind(this);
    this.doNotUpdateReturningInsurance = this.doNotUpdateReturningInsurance.bind(this);
    this.buttonSelected = this.buttonSelected.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleCardDataSync = this.handleCardDataSync.bind(this);
  }

  async initMoolah(e) {
    await $.getScript('https://poynt.net/snippet/poynt-collect/bundle.js');
    const collect = new window.TokenizeJs(
      this.poyntCode,
      'urn:aid:df5ae5f0-6360-4024-819f-b7a17b92d5c7',
    );

    const options = {
      iFrame: {
        width: '100%',
        height: '170px',
        border: '0',
      },
      style: {
        theme: 'customer',
      },
      displayComponents: {
        firstName: false,
        lastName: false,
        emailAddress: false,
        submitButton: false,
        showEndingPage: false,
        labels: true,
        zipCode: true,
      },
    };
    collect.mount('moolah-payment-iframe', document, options);
    collect.on('nonce', (data) => {
      if (!data?.type == 'nonce') {
        return;
      }

      this.token = data.data.nonce;
      this.lastfour = '1234';
      this.expiry = '1250';
      this.setState({ moolahNonceComplete: true });
      this.handleSubmit();
    });

    //error handling if fields are missing or invalid
    collect.on('error', (event) => {
      if (
        event &&
        event.data &&
        event.data.error &&
        event.data.error.source === 'submit'
      ) {
        alert(event.data.error.message);
      }
    });
    this.collect = collect;
  }

  componentDidMount() {
    //blur background for who is scheduling modal
    if (this.state.showGuarantorModal) {
      const background = document.getElementById('scrollTop');
      background.classList.add('remove-scroll');
    }
    if (
      this.state.paymentsActive &&
      this.state.ccApptIncluded &&
      this.state.merchant === 'moolah'
    ) {
      this.TokenizeJs = window.TokenizeJs;
      this.authorize = window.authorize;
      this.initMoolah();
    }
    const scrolly = document.getElementsByClassName('scheduleAppointmentContainer');
    scrolly[0].scrollIntoView({ behavior: 'smooth', block: 'start' });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem('phoneType', true);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem('phoneType', false);
      this.setState({ hover: true });
    }

    const currentPage = window.sessionStorage.currentPage;

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 8) {
        window.sessionStorage.setItem('currentPage', 9);
      } else if (parseInt(currentPage) === 10) {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(
            `/${this.props.match.params.id}/${this.props.match.params.analytics}/${
              parseInt(currentPage) !== 0 ? currentPage : ''
            }`,
          );
        } else {
          this.props.history.push(
            `/${this.props.match.params.id}/${
              parseInt(currentPage) !== 0 ? currentPage : ''
            }`,
          );
        }
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(
            `/${this.props.match.params.id}/${this.props.match.params.analytics}/${
              parseInt(currentPage) !== 0 ? currentPage : ''
            }`,
          );
        } else {
          this.props.history.push(
            `/${this.props.match.params.id}/${
              parseInt(currentPage) !== 0 ? currentPage : ''
            }`,
          );
        }
      }
    } else if (parseInt(currentPage) === 10) {
      if (this.props.match.params.analytics !== undefined) {
        this.props.history.push(
          `/${this.props.match.params.id}/${this.props.match.params.analytics}/${
            parseInt(currentPage) !== 0 ? currentPage : ''
          }`,
        );
      } else {
        this.props.history.push(
          `/${this.props.match.params.id}/${
            parseInt(currentPage) !== 0 ? currentPage : ''
          }`,
        );
      }

      window.securedNav = false;
    } else {
      if (parseInt(currentPage) === 8 || parseInt(currentPage) === 9) {
        window.sessionStorage.setItem('currentPage', 9);
      } else {
        if (window.securedNav === true && parseInt(currentPage) === 2) {
          window.sessionStorage.setItem('currentPage', 1);
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.replace(
              `/${this.props.match.params.id}/${this.props.match.params.analytics}/${
                parseInt(currentPage) !== 0 ? parseInt(currentPage) - 1 : ''
              }`,
            );
          } else {
            this.props.history.replace(
              `/${this.props.match.params.id}/${
                parseInt(currentPage) !== 0 ? parseInt(currentPage) - 1 : ''
              }`,
            );
          }
        }
      }

      window.securedNav = false;
    }

    let returning = this.state.returning;

    if (returning !== 'new') {
      this.setState({
        insurance: 'already provided',
      });
    }
  }

  handleKeyPress(value, event) {
    if (event.key === 'Enter') {
      const arr = ['fname', 'lname', 'email', 'month', 'day', 'year', 'phone'];

      this.refs[arr[arr.indexOf(value) + 1]].focus();
    }
  }

  filterValue(obj, key, value) {
    return obj.find(function (v) {
      return v[key] === value;
    });
  }

  async componentDidUpdate(prevProps) {
    // if (this.state.showGuarantorModal) {
    //   console.log('comp did update')
    //   const background = document.getElementsByClassName("blurry");
    //   $(background).attr('style',  'filter: blur(5px); pointer-events: none;');
    // }
    if (prevProps.verify !== this.props.verify && this.props.verify === 'approved') {
      const appointmentId = window.sessionStorage.appointmentId;
      let scheduleApp = JSON.parse(window.sessionStorage.selectedAppointment);
      let returning = this.state.returning;
      let insurance_change = false;

      let providerId = window.sessionStorage.providerId;

      let providerIdx = this.props.config.providers.findIndex(
        (providers) => parseInt(providerId) === parseInt(providers.provider_assoc),
      );
      let foundProvider = this.props.config.providers[providerIdx];

      if (foundProvider) {
        providerId = foundProvider.provider_id;
      }

      if (returning === 'new') {
        returning = false;
      } else if (returning === 'returningUpdate') {
        returning = true;
        insurance_change = true;
      } else {
        returning = true;
      }

      if (typeof scheduleApp === 'object' && scheduleApp.length > 0) {
        scheduleApp = scheduleApp[0];
      }

      let client;

      if (window.sessionStorage.locationId) {
        client = window.sessionStorage.locationId;
      } else {
        client = this.props.match.params.id;
      }

      let insurance;

      if (
        this.state.hasInsurance === 'Yes' &&
        this.state.selectedOption.label &&
        this.state.selectedOption.label !== 'Other'
      ) {
        insurance = this.state.selectedOption.label;
      } else {
        insurance = this.state.insuranceOther;
      }

      let number = this.state.phone.replace(/[^\d]/g, '');

      var qAs = [];

      const currentLanguageName = getFullLanguageName();

      var questions =
        this.props.config.questions !== undefined ? this.props.config.questions : [];

      questions.forEach((el) => {
        var qA = {
          q: el.question,
          a:
            this.state[`question-${el.id}`] !== undefined
              ? this.state[`question-${el.id}`]
              : '',
        };
        qAs.push(qA);
      });


      let data = {
        client: client,
        fname: this.state.fname,
        lname: this.state.lname,
        email: this.state.email,
        dob: this.state.dob,
        phone: number,
        appointment_id: appointmentId,
        appointment_date: scheduleApp.date,
        appointment_time: scheduleApp.starttime,
        operatory: scheduleApp.operatory,
        provider_id: providerId,
        returning: returning,
        insurance: insurance,
        insuranceChange: insurance_change,
        language: currentLanguageName,
        subscriber_id: this.state.subscriber_id !== '' ? this.state.subscriber_id : '0',
        tracking: this.props.match.params.analytics,
        qas: qAs,
        guarantor: this.state.guarantor,
        liineSessionId: window.sessionStorage.getItem('liineSessionId'),
        appointmentName: window.sessionStorage.getItem('appointmentName')
      };

      await this.handleCreditCard(data);

      this.props.createAppointment(data).then(() => {
        window.sessionStorage.setItem('created', this.props.appointments);

        window.sessionStorage.setItem('currentPage', 9);
        this.createCookieInHour(
          this.state.guarantor ? this.state.guarantor.phone : this.state.phone,
          'approved',
          1,
        );

        this.setState(
          {
            loading: false,
            show: !this.state.show,
          },
          () => {
            if (this.props.match.params.analytics !== undefined) {
              this.props.history.push(
                `/${this.props.match.params.id}/${this.props.match.params.analytics}/10`,
              );
            } else {
              this.props.history.push(`/${this.props.match.params.id}/10`);
            }
          },
        );
      });
    }
  }

  handleScheduleFor = (answer) => {
    if (answer === 'myself') {
      this.setState({ scheduleFor: answer, showGuarantorModal: false }, () => {
        const background = document.getElementById('scrollTop');
        background.classList.remove('remove-scroll');
      });
    } else if (answer === 'someoneElse') {
      this.setState({ scheduleFor: answer });
    }
  };

  /**
   * @typedef {Object} GuarantorInfo
   * @property {boolean} isGuarantor
   * @property {string} fname
   * @property {string} lname
   * @property {string} email
   * @property {string} phone
   * @property {string} dob
   */
  /**
   * @param {GuarantorInfo} guarantor
   */
  handleCloseGuarantorModal = (guarantor) => {
    this.setState({ guarantor, showGuarantorModal: false }, () => {
      const background = document.getElementById('scrollTop');
      background.classList.remove('remove-scroll');
    });
  };

  handleShowGuarantorModal = (e) => {
    e.preventDefault();
    this.setState({ showGuarantorModal: true }, () => {
      const modal = document.getElementById('scheduler-modal');
      modal.scrollIntoView({ behavior: 'smooth', block: 'end' });
      const background = document.getElementById('scrollTop');
      background.classList.add('remove-scroll');
    });
  };

  showModal = (e) => {
    this.setState(
      {
        show: !this.state.show,
      },
      () => {
        if (document.getElementById('modal')) {
          this.scrollToModal();
        }
      },
    );

    if (!this.state.show) {
      const background = document.getElementsByClassName('blurry');
      $(background).attr('style', 'filter: blur(5px);');
    } else {
      const background = document.getElementsByClassName('blurry');
      $(background).attr('style', 'filter: none');
    }
  };

  getCookie(name) {
    let dc = document.cookie;
    let prefix = name + '=';
    let begin = dc.indexOf('; ' + prefix);
    let end;

    if (parseInt(begin) === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
    } else {
      begin += 2;
      end = document.cookie.indexOf(';', begin);

      if (parseInt(end) === -1) {
        end = dc.length;
      }
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  async handleCreditCard(data) {
    //tokenize card
    if (
      !(this.state.paymentsActive && this.state.ccApptIncluded && this.state.ccHandleMode)
    ) {
      return;
    }
    if (this.state.merchant === 'cardconnect') {
      try {
        const { token, expiry, lastfour } = await tokenizeCard(
          this.cardNumber,
          this.expMonth,
          this.expYear,
        );
        data.token = token;
        data.expiry = expiry.toString();
        data.lastfour = lastfour;
      } catch (err) {
        console.error('error when tokenizing', err);
        return;
      }
    } else if (this.state.merchant === 'moolah') {
      data.token = this.token;
      data.expiry = this.expiry;
      data.lastfour = this.lastfour;
    } else {
      console.error('Unrecognized vendor: ', this.state.merchant);
    }
  }

  update(field) {
    return (e) => {
      this.setState({ [field]: e.target.value });
    };
  }

  scrollToBottom() {
    if (JSON.parse(window.sessionStorage.phoneType))
      this.el.getElementsByTagName('button')[0].scrollIntoView({ behavior: 'smooth' });
  }

  scrollToBottomPage() {
    const first = document.getElementById('scrollTop');
    if (JSON.parse(window.sessionStorage.phoneType))
      first.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  scrollToFirst() {
    const first = document.getElementById('firstInput');
    if (JSON.parse(window.sessionStorage.phoneType))
      first.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  scrollToLast() {
    const last = document.getElementById('lastInput');
    if (JSON.parse(window.sessionStorage.phoneType))
      last.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  scrollToEmail() {
    const email = document.getElementById('emailInput');
    if (JSON.parse(window.sessionStorage.phoneType))
      email.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  scrollToPhone() {
    const phone = document.getElementById('phoneInput');
    if (JSON.parse(window.sessionStorage.phoneType))
      phone.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  scrollToModal() {
    const modal = document.getElementById('modal');
    if (JSON.parse(window.sessionStorage.phoneType))
      modal.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  handleDob(e) {
    const month = document.getElementById('date-of-birth-month');
    const day = document.getElementById('date-of-birth-day');
    const year = document.getElementById('date-of-birth-year');
    if (!+day.value || !year.value) {
      this.setState({ dob: '' });
      this.update('dob');
      return;
    }

    const currentYear = new Date().getFullYear();
    if (day.value && day.value < 1) day.value = 1;
    if (day.value && day.value > 31) day.value = 31;
    if (year.value && year.value.length >= 4 && year.value < 1900) year.value = 1900;
    if (year.value && year.value.length >= 4 && year.value > currentYear)
      year.value = currentYear;
    if (year.value.length < 4) {
      this.setState({ dob: '' });
      this.update('dob');
      return;
    }
    let dayVal = day.value;

    let monthVal = month.options[month.selectedIndex].value;
    if (monthVal.length === 1) monthVal = '0' + monthVal;
    if (dayVal.length === 1) dayVal = '0' + day.value;
    const dobVal = year.value + '-' + monthVal + '-' + dayVal;
    if (
      moment(dobVal, 'YYYY-MM-DD', true).isValid() &&
      moment(dobVal, 'YYYY-MM-DD').isBefore()
    ) {
      this.setState({ dob: dobVal });
      this.update('dob');
      day.classList.remove('invalid');
      month.classList.remove('invalid');
      year.classList.remove('invalid');
    } else {
      this.setState({ dob: '' });
      this.update('dob');
      day.classList.add('invalid');
      month.classList.add('invalid');
      year.classList.add('invalid');
    }
  }

  handleEmail() {
    let email = document.getElementById('email');
    if (
      email.value.length < 5 ||
      !email.value.includes('@') ||
      !email.value.includes('.')
    ) {
      email.classList.add('invalid');
      this.setState({ email: email.value });
    } else {
      this.setState({ email: email.value });
      this.update('email');
      email.classList.remove('invalid');
    }
  }
  async handleMoolahSubmit(e) {
    e && e.preventDefault();
    if (!this.ccAgree) {
      alert('Must check the "I understand" box');
      return;
    }
    this.collect.getNonce({});
  }
  async handleSubmit(e) {
    e && e.preventDefault();
    let data = {};
    var qAs = [];

    if (this.state.guarantor?.dob === this.state.dob) {
      alert(
        'You have indicated that you are booking this appointment for someone else; however, the date of birth provided on this page matches the date of birth provided on the previous page. Please ensure your information is provided on the previous page and the patient’s information is provided on this page.',
      );
      this.setState({ dobAlert: true });
      return;
    } else {
      this.state.dobAlert && this.setState({ dobAlert: false });
    }

    if (this.state.paymentsActive && this.state.ccHandleMode) {
      const alerts = [];
      if (this.state.merchant === 'cardconnect') {
        if (!this.expYear || this.expYear === '-') {
          alerts.push('Missing Expiration Year');
        }
        if (!this.expMonth || this.expMonth === '-') {
          alerts.push('Missing Expiration Month');
        }
        if (!this.cardNumber || this.cardNumber.toString().length !== 16) {
          alerts.push('Missing or invalid credit card number');
        }
        if (
          moment().format('YYYY') === this.expYear &&
          +moment().format('M') > +this.expMonth
        ) {
          alerts.push('Expiration date cannot be in the past');
        }
      }
      if (!this.ccAgree) {
        alerts.push('Must check the "I understand" box');
      }
      if (alerts.length) {
        alert(alerts.join(', '));
        return;
      }
    }

    var questions =
      this.props.config.questions !== undefined ? this.props.config.questions : [];

    questions.forEach((el) => {
      var qA = {
        q: el.question,
        a:
          this.state[`question-${el.id}`] !== undefined
            ? this.state[`question-${el.id}`]
            : '',
      };
      qAs.push(qA);
    });
    const myCookie = this.getCookie(
      this.state.guarantor ? this.state.guarantor.phone : this.state.phone,
    );
    const appointmentId = window.sessionStorage.appointmentId;
    let scheduleApp = JSON.parse(window.sessionStorage.selectedAppointment);
    let returning = this.state.returning;
    let insurance_change = false;

    let providerId = window.sessionStorage.providerId;

    let providerIdx = this.props.config.providers.findIndex(
      (providers) => parseInt(providerId) === parseInt(providers.provider_assoc),
    );
    let foundProvider = this.props.config.providers[providerIdx];

    if (foundProvider) {
      providerId = foundProvider.provider_id;
    }

    if (returning === 'new') {
      returning = false;
    } else if (returning === 'returningUpdate') {
      returning = true;
      insurance_change = true;
    } else {
      returning = true;
    }

    if (typeof scheduleApp === 'object' && scheduleApp.length > 0) {
      scheduleApp = scheduleApp[0];
    }

    let client;

    if (window.sessionStorage.locationId) {
      client = window.sessionStorage.locationId;
    } else {
      client = this.props.match.params.id;
    }

    let insurance;

    if (
      this.state.hasInsurance === 'Yes' &&
      this.state.selectedOption.label &&
      this.state.selectedOption.label !== 'Other'
    ) {
      insurance = this.state.selectedOption.label;
    } else {
      insurance = this.state.insuranceOther;
    }

    let number = this.state.phone.replace(/[^\d]/g, '');

    const currentLanguageName = getFullLanguageName();

    data = {
      client: client,
      fname: this.state.fname,
      lname: this.state.lname,
      email: this.state.email,
      dob: this.state.dob,
      phone: number,
      appointment_id: appointmentId,
      appointment_date: scheduleApp.date,
      appointment_time: scheduleApp.starttime,
      operatory: scheduleApp.operatory,
      provider_id: providerId,
      returning: returning,
      insurance: insurance,
      insuranceChange: insurance_change,
      language: currentLanguageName,
      subscriber_id: this.state.subscriber_id !== '' ? this.state.subscriber_id : '0',
      tracking: this.props.match.params.analytics,
      qas: qAs,
      guarantor: this.state.guarantor,
      liineSessionId: window.sessionStorage.getItem('liineSessionId'),
      appointmentName: window.sessionStorage.getItem('appointmentName')
    };

    // Create appointment
    if (myCookie) {
      if (myCookie === 'approved') {
        this.setState({ loading: true });
        await this.handleCreditCard(data);
        this.props.createAppointment(data).then(() => {
          window.sessionStorage.setItem('created', this.props.appointments);

          this.setState({ loading: false });
          window.sessionStorage.removeItem('backPage');
          window.sessionStorage.setItem('currentPage', 9);

          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(
              `/${this.props.match.params.id}/${this.props.match.params.analytics}/10`,
            );
          } else {
            this.props.history.push(`/${this.props.match.params.id}/10`);
          }
        });
      } else {
        this.showModal();

        // this is used to handle tracking insurance use. will display insurance selection on next appointment creation, or button to change
        this.props.sendVerification(
          this.state.guarantor ? this.state.guarantor.phone : this.state.phone,
        );
      }
    } else {
      this.showModal();

      // this is used to handle tracking insurance use. will display insurance selection on next appointment creation, or button to change
      this.props.sendVerification(
        this.state.guarantor ? this.state.guarantor.phone : this.state.phone,
      );
    }
  }

  handleInsurance(selectedOption) {
    this.setState({
      selectedOption,
      showInsuranceMessage: true,
    });

    if (selectedOption.label === 'Other') {
      this.setState(
        {
          showInsuranceField: true,
        },
        () => {
          this.scrollToBottom();
        },
      );
    }
  }

  handlePhone(phoneNumber) {
    let ph = phoneNumber.currentTarget.value
      .replace(/\D/g, '')
      .replace(/^1/, '')
      .substring(0, 10);

    let len = parseInt(ph.length);

    if (len < 3) {
      ph = '(' + ph;
    } else if (len === 3) {
      ph = '(' + ph + ') ';
    } else if (len < 6) {
      ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6);
    } else if (len === 6) {
      ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6) + '-';
    } else {
      ph =
        '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6) + '-' + ph.substring(6, 10);
    }

    this.setState({
      phone: ph,
    });
  }

  onKeyPress(e) {
    let ph = e.target.value.replace(/\D/g, '').substring(0, 10);

    let deleteKey = e.key === 'Backspace';
    let len = parseInt(ph.length);

    if (len < 3) {
      ph = '(' + ph;
    } else if (len === 3) {
      ph = '(' + ph + (deleteKey ? '' : ') ');
    } else if (len < 6) {
      ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6);
    } else if (len === 6) {
      ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6) + (deleteKey ? '' : '-');
    } else {
      ph =
        '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6) + '-' + ph.substring(6, 10);
    }

    this.setState({
      phone: ph,
    });
  }

  updateInsurance(e) {
    e.preventDefault();

    this.setState(
      {
        insurance: '',
        selectedOption: null,
      },
      () => {
        this.setState(this.state);
      },
    );
  }

  selectionChanged = (type) => (ev) => {
    this.setState({ [type]: ev.target.value });
  };

  dropdownChanged = (type) => (ev) => {
    this.setState({ [type]: ev.label });
  };

  updateReturningInsurance(e) {
    e.preventDefault();

    this.setState({
      hasInsurance: '',
      insurance_change: true,
      insurance: '',
      selectedOption: null,
      returning: 'returningUpdate',
    });
  }

  doNotUpdateReturningInsurance(e) {
    e.preventDefault();

    this.setState({
      hasInsurance: 'Yes',
      insurance_change: false,
      insurance: 'No',
      selectedOption: 'already provided',
      returning: '',
    });

    this.scrollToBottom();
  }

  createCookieInHour = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime() + hourToExpire * cookieExpiration);
    document.cookie =
      cookieName + ' = ' + cookieValue + '; expires = ' + date.toGMTString();
  };

  buttonSelected = (selectedButton) => (ev) => {
    if (this.state.insurance === selectedButton) {
      return;
    }
    this.setState({
      insurance: selectedButton,
    });

    if (selectedButton === 'Yes') {
      this.setState({
        hasInsurance: selectedButton,
        selectedOption: null,
        showInsuranceSelection: true,
        showNoInsuranceMessage: false,
      });
    } else {
      this.setState({
        hasInsurance: selectedButton,
        selectedOption: 'already provided',
        showInsuranceSelection: false,
        showNoInsuranceMessage: true,
        subscriber_id: '',
      });
    }

    this.scrollToBottom();
  };

  handleCardDataSync({ cardNumber, expMonth, expYear, ccAgree }) {
    this.cardNumber = cardNumber.replace(/[\s]/g, '');
    this.expMonth = expMonth;
    this.expYear = expYear;
    this.ccAgree = ccAgree;
  }

  render() {
    const {t}= this.props
    const currentLanguage = i18n.language;
    var questions =
      this.props.config.questions !== undefined ? this.props.config.questions : [];

    const scroll = document.getElementsByClassName('scheduleAppointmentContainer');

    if (this.state.hasInsurance === 'Yes') {
      if (
        window.innerHeight > 728 &&
        window.innerHeight < 890 &&
        JSON.parse(window.sessionStorage.phoneType)
      ) {
        $(scroll).attr('style', 'margin-top: 10%');
      } else {
        $(scroll).attr('style', 'margin-top: 0% !important');
        $(scroll).attr('style', 'padding-top: 0% !important');
      }
    } else {
      $(scroll).attr('style', 'margin-top: 0% !important');
      $(scroll).attr('style', 'padding-top: 0% !important');
    }

    const backButton = document.getElementsByClassName('backButton');

    if (this.state.loading) {
      $(backButton).addClass('hideButton');
    } else {
      $(backButton).removeClass('hideButton');
    }

    const { selectedOption } = this.state;

    const { fname, lname, dob, email, phone, insurance, subscriber_id } = this.state;

    const myCookie = this.getCookie(
      this.state.guarantor ? this.state.guarantor.phone : phone,
    );

    let selectedAppointment;

    if (window.sessionStorage.selectedAppointment) {
      selectedAppointment = JSON.parse(window.sessionStorage.selectedAppointment);
    }

    if (typeof selectedAppointment === 'object' && selectedAppointment.length > 0) {
      selectedAppointment = selectedAppointment[0];
    }

    let provider;
    let providerId;
    let providerIndex;

    if (parseInt(window.sessionStorage.providerId) !== 0) {
      providerIndex = this.props.config.providers.findIndex(
        (provider) =>
          parseInt(provider.provider_assoc) ===
          parseInt(window.sessionStorage.providerId),
      );
      provider = this.props.config.providers[providerIndex];
    } else {
      // For first available appointments
      // check if providerId has been added to selectedAppointment (available in client-side build version > 4.13.2)
      // selectedAppointment will be either an object or an Array with 1 appointment object
      const selectedAppointment = JSON.parse(window.sessionStorage.selectedAppointment);
      const apptProvider = Array.isArray(selectedAppointment)
        ? selectedAppointment[0].providerId
        : selectedAppointment.providerId;
      if (apptProvider) {
        providerIndex = this.props.config.providers.findIndex(
          (provider) => parseInt(provider.provider_assoc) === parseInt(apptProvider),
        );
        provider = this.props.config.providers[providerIndex];
      }
    }

    const { practicePhone } = this.props.config;

    const activeInsuranceOptions = this.props.config.insurance[0].active;
    let inNetworkInsuranceOptions = this.props.config.insurance[1].inNetwork;

    // filter out inNetwork Insurance options if selected provider has exclusions
    if (provider?.insexclude) {
      inNetworkInsuranceOptions = inNetworkInsuranceOptions.filter(
        (opt) => !provider.insexclude.includes(`${opt.value}`),
      );
    }

    let allInsuranceOptions = _.unionBy(
      activeInsuranceOptions,
      inNetworkInsuranceOptions,
      'label',
    );
    allInsuranceOptions.push({ value: 0, label: 'Other' });

    const insuranceMessages = this.props.config.messages;

    let regex = '{{practicephone}}';
    let providerRegex = '{{provider}}';
    let takesRegex = 'takes';
    let insuranceVerbLinkRegex = ' is in ';

    let insuranceMessage;
    let insuranceMsgProvider;
    let insuranceTakesWord;
    let insuranceVerbLink;

    if (provider) {
      insuranceMsgProvider = provider.name;
      insuranceTakesWord = 'takes';
      insuranceVerbLink = ' is in ';
    } else {
      insuranceMsgProvider = 'We';
      insuranceTakesWord = 'take';
      insuranceVerbLink = ' are in ';
    }

    if (this.state.showNoInsuranceMessage) {
      insuranceMessage = (
        <div
          id="insuranceId"
          ref={(el) => {
            this.el = el;
          }}
          className="insuranceMessage"
        >
          {(() => {
            const filteredMessage = this.filterValue(insuranceMessages, 'type', 'noinsurance');
            return currentLanguage === 'es' && filteredMessage.message_translated
              ? filteredMessage.message_translated
                  .replace(regex, practicePhone)
                  .replace(providerRegex, insuranceMsgProvider)
              : filteredMessage.message
                  .replace(regex, practicePhone)
                  .replace(providerRegex, insuranceMsgProvider)
                  .replace(takesRegex, insuranceTakesWord)
                  .replace(insuranceVerbLinkRegex, insuranceVerbLink);
          })()}
        </div>
      );
    } else {
      if (this.state.showInsuranceMessage) {
        if (_.find(inNetworkInsuranceOptions, this.state.selectedOption)) {
          insuranceMessage = (
            <div
              id="insuranceId"
              ref={(el) => {
                this.el = el;
              }}
              className="insuranceMessage"
            >
              {(() => {
                const filteredMessage = this.filterValue(insuranceMessages, 'type', 'innetwork');
                return currentLanguage === 'es' && filteredMessage.message_translated
                  ? filteredMessage.message_translated
                      .replace(regex, practicePhone)
                      .replace(providerRegex, insuranceMsgProvider)
                  : filteredMessage.message
                      .replace(regex, practicePhone)
                      .replace(providerRegex, insuranceMsgProvider)
                      .replace(takesRegex, insuranceTakesWord)
                      .replace(insuranceVerbLinkRegex, insuranceVerbLink);
              })()}
            </div>
          );
        } else if (_.find(activeInsuranceOptions, this.state.selectedOption)) {
          insuranceMessage = (
            <div
              id="insuranceId"
              ref={(el) => {
                this.el = el;
              }}
              className="insuranceMessage"
            >
              {(() => {
                const filteredMessage = this.filterValue(insuranceMessages, 'type', 'outnetwork');
                return currentLanguage === 'es' && filteredMessage.message_translated
                  ? filteredMessage.message_translated
                      .replace(regex, practicePhone)
                      .replace(providerRegex, insuranceMsgProvider)
                  : filteredMessage.message
                      .replace(regex, practicePhone)
                      .replace(providerRegex, insuranceMsgProvider)
                      .replace(takesRegex, insuranceTakesWord)
                      .replace(insuranceVerbLinkRegex, insuranceVerbLink);
              })()}
            </div>
          );
        } else {
          insuranceMessage = (
            <div
              id="insuranceId"
              ref={(el) => {
                this.el = el;
              }}
              className="insuranceMessage"
            >
            {(() => {
              const filteredMessage = this.filterValue(insuranceMessages, 'type', 'other');
              return currentLanguage === 'es' && filteredMessage.message_translated
                ? filteredMessage.message_translated
                    .replace(regex, practicePhone)
                    .replace(providerRegex, insuranceMsgProvider)
                : filteredMessage.message
                    .replace(regex, practicePhone)
                    .replace(providerRegex, insuranceMsgProvider)
                    .replace(takesRegex, insuranceTakesWord)
                    .replace(insuranceVerbLinkRegex, insuranceVerbLink);
            })()}
            </div>
          );
        }
      } else {
        insuranceMessage = (
          <div
            id="insuranceId"
            ref={(el) => {
              this.el = el;
            }}
            className="insuranceMessage"
          ></div>
        );
      }
    }

    let insuranceSelection;

    if (this.state.showInsuranceSelection) {
      insuranceSelection = (
        <div className="row justify-content-center">
          <div className="insuranceSelection col-sm-12 col-md-8">
            <Select
              maxMenuHeight={225}
              isClearable={false}
              isSearchable={false}
              onChange={this.handleInsurance}
              components={{ SingleValue }}
              options={allInsuranceOptions}
              placeholder={currentLanguage === 'es' ? 'Seleccionar...' : 'Select...'}
            />
          </div>
        </div>
      );
    }

    let ins = false;

    // checks required fields are filled out to disable/enable submit button
    if (
      insurance.length &&
      selectedOption?.label !== 'Other' &&
      subscriber_id.length > 1
    ) {
      ins = true;
    } else if (
      selectedOption?.label === 'Other' &&
      this.state.insuranceOther !== 'No' &&
      this.state.insuranceOther.length > 1 &&
      subscriber_id.length > 1
    ) {
      ins = true;
    } else if (insurance === 'No' && selectedOption !== null) {
      ins = true;
    }

    let sendCodeAlert;
    if (myCookie && myCookie === 'approved') {
      sendCodeAlert = (
        <div className={`col col-12 codeAlert ${this.state.showCodeAlert}`}>
          {t("You have already confirmed your identity")}
        </div>
      );
    } else {
      sendCodeAlert = (
        <div className={`col col-12 codeAlert ${this.state.showCodeAlert}`}>
          {t("We’ll text you a 6-digit code to confirm this appointment")}.
        </div>
      );
    }

    let insuranceDiv;
    let insuranceFillField;
    let subscriberFillField;
    const questionsDiv = [];
    const questionsPlaces = [];
    const topQuestions = [];
    const returning = this.state.returning;

    if (returning !== 'new') {
      questions.forEach((ele, i) => {
        if (ele.type === 1 && ele.condition === 3) {
          questionsPlaces.push(ele);
          topQuestions.push(
            <TextQuestion
              dob={this.state.dob}
              onChange={this.update(`question-${ele.id}`)}
              value={this.state[`${ele}`]}
              refs={`textInput${ele.id}`}
              disabled={this.state.show}
              key={i}
              data={ele}
            />,
          );
        } else if (ele.type === 2 && ele.condition === 3) {
          questionsPlaces.push(ele);
          topQuestions.push(
            <RadioQuestion
              dob={this.state.dob}
              state={this.state}
              onChange={this.selectionChanged(`question-${ele.id}`)}
              key={i}
              data={ele}
            />,
          );
        } else if (ele.type === 3 && ele.condition === 3) {
          questionsPlaces.push(ele);
          topQuestions.push(
            <DropdownQuestion
              dob={this.state.dob}
              onChange={this.dropdownChanged(`question-${ele.id}`)}
              key={i}
              data={ele}
            />,
          );
        }
      });
    }

    if (returning === 'returning' || returning === '') {
      insuranceDiv = (
        <div className="insContainer">
          <div className="insuranceDescription">
            {t("Has your insurance changed since you last visited us")}?
          </div>

          <Button
            color={this.props.config.color}
            className="insurance updateInsurance"
            type="button"
            onClick={this.updateReturningInsurance}
          >
            {t("Yes")}
          </Button>
          <Button
            color={this.props.config.color}
            className={`insurance updateInsurance ${
              'Yes' === this.state.hasInsurance ? 'selected' : ''
            }`}
            type="button"
            onClick={this.doNotUpdateReturningInsurance}
          >
            {t("No")}
          </Button>
        </div>
      );
    } else {
      if (
        this.state.insurance &&
        this.state.insurance === 'No' &&
        returning === 'returning'
      ) {
        // returning that do not have insurance

        insuranceDiv = (
          <div className="insContainer">
            <div className="insuranceDescription">
              {t("Our records show that you do not have insurance")}.
            </div>

            <Button
              color={this.props.config.color}
              className="insurance updateInsurance"
              type="button"
              onClick={this.updateInsurance}
            >
              {t("Update")}
            </Button>
          </div>
        );
      } else if (
        this.state.insurance &&
        this.state.insurance !== 'No' &&
        returning === 'returning'
      ) {
        // returning that have insurance

        questions.forEach((ele, i) => {
          if (ele.type === 1 && ele.condition === 6) {
            questionsPlaces.push(ele);
            questionsDiv.push(
              <TextQuestion
                dob={this.state.dob}
                onChange={this.update(`question-${ele.id}`)}
                value={this.state[`${ele}`]}
                refs={`textInput${ele.id}`}
                disabled={this.state.show}
                key={i}
                data={ele}
              />,
            );
          } else if (ele.type === 2 && ele.condition === 6) {
            questionsPlaces.push(ele);
            questionsDiv.push(
              <RadioQuestion
                dob={this.state.dob}
                state={this.state}
                onChange={this.selectionChanged(`question-${ele.id}`)}
                key={i}
                data={ele}
              />,
            );
          } else if (ele.type === 3 && ele.condition === 6) {
            questionsPlaces.push(ele);
            questionsDiv.push(
              <DropdownQuestion
                dob={this.state.dob}
                onChange={this.dropdownChanged(`question-${ele.id}`)}
                key={i}
                data={ele}
              />,
            );
          }
        });

        insuranceDiv = (
          <div className="insContainer">
            <div className="insuranceDescription">
              {t("Our records show that you have {{insuranceLabel}} insurance",{insuranceLabel:this.state.insurance !== 'Yes'
                ? this.state.insurance
                : this.state.selectedOption.label})}.
            </div>

            <Button
              color={this.props.config.color}
              className="insurance updateInsurance"
              type="button"
              onClick={this.updateInsurance}
            >
              {t("Update")}
            </Button>
          </div>
        );
      } else {
        // new that have insurance
        if (this.state.insurance === 'Yes' && returning !== 'returningUpdate') {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 4) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <TextQuestion
                  dob={this.state.dob}
                  onChange={this.update(`question-${ele.id}`)}
                  value={this.state[`${ele}`]}
                  refs={`textInput${ele.id}`}
                  disabled={this.state.show}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 2 && ele.condition === 4) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <RadioQuestion
                  dob={this.state.dob}
                  state={this.state}
                  onChange={this.selectionChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 3 && ele.condition === 4) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <DropdownQuestion
                  dob={this.state.dob}
                  onChange={this.dropdownChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            }
          });
        } else if (this.state.insurance === 'No' && returning !== 'returningUpdate') {
          // new that do not have insurance
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 5) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <TextQuestion
                  dob={this.state.dob}
                  onChange={this.update(`question-${ele.id}`)}
                  value={this.state[`${ele}`]}
                  handleKeyPress={(event) =>
                    this.handleKeyPress(`textInput${ele.id}`, event)
                  }
                  refs={`textInput${ele.id}`}
                  onKeyDown={this.onKeyPress}
                  disabled={this.state.show}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 2 && ele.condition === 5) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <RadioQuestion
                  dob={this.state.dob}
                  state={this.state}
                  onChange={this.selectionChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 3 && ele.condition === 5) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <DropdownQuestion
                  dob={this.state.dob}
                  onChange={this.dropdownChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            }
          });
        }

        if (returning === 'new') {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 2) {
              questionsPlaces.push(ele);
              topQuestions.push(
                <TextQuestion
                  dob={this.state.dob}
                  onChange={this.update(`question-${ele.id}`)}
                  value={this.state[`${ele}`]}
                  refs={`textInput${ele.id}`}
                  disabled={this.state.show}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 2 && ele.condition === 2) {
              questionsPlaces.push(ele);
              topQuestions.push(
                <RadioQuestion
                  dob={this.state.dob}
                  state={this.state}
                  onChange={this.selectionChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 3 && ele.condition === 2) {
              questionsPlaces.push(ele);
              topQuestions.push(
                <DropdownQuestion
                  dob={this.state.dob}
                  onChange={this.dropdownChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            }
          });
        }

        if (returning === 'returningUpdate' && this.state.insurance === 'Yes') {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 6) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <TextQuestion
                  dob={this.state.dob}
                  onChange={this.update(`question-${ele.id}`)}
                  value={this.state[`${ele}`]}
                  refs={`textInput${ele.id}`}
                  disabled={this.state.show}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 2 && ele.condition === 6) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <RadioQuestion
                  dob={this.state.dob}
                  state={this.state}
                  onChange={this.selectionChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 3 && ele.condition === 6) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <DropdownQuestion
                  dob={this.state.dob}
                  onChange={this.dropdownChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            }
          });
        }

        if (returning === 'returningUpdate' && this.state.insurance === 'No') {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 7) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <TextQuestion
                  dob={this.state.dob}
                  onChange={this.update(`question-${ele.id}`)}
                  value={this.state[`${ele}`]}
                  refs={`textInput${ele.id}`}
                  disabled={this.state.show}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 2 && ele.condition === 7) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <RadioQuestion
                  dob={this.state.dob}
                  state={this.state}
                  onChange={this.selectionChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            } else if (ele.type === 3 && ele.condition === 7) {
              questionsPlaces.push(ele);
              questionsDiv.push(
                <DropdownQuestion
                  dob={this.state.dob}
                  onChange={this.dropdownChanged(`question-${ele.id}`)}
                  key={i}
                  data={ele}
                />,
              );
            }
          });
        }

        if (selectedOption !== null && selectedOption.label === 'Other') {
          insuranceFillField = (
            <div className="row justify-content-center">
              <div className="insuranceInput field col-sm-12 col-md-8">
                <input
                  disabled={this.state.show}
                  maxLength="75"
                  onChange={this.update('insuranceOther')}
                  type="name"
                  className="form-control"
                  id="name"
                  placeholder={`${t("Insurance Provider")}*`}
                />
              </div>
            </div>
          );

          subscriberFillField = (
            <div className="row justify-content-center">
              <div className="subscriberInput field col-sm-12 col-md-8">
                <input
                  disabled={this.state.show}
                  onChange={this.update('subscriber_id')}
                  type="name"
                  className="form-control"
                  id="name"
                  placeholder={`${t("Subscriber ID")} #*`}
                />
              </div>
            </div>
          );
        } else if (
          selectedOption !== null &&
          allInsuranceOptions.includes(selectedOption)
        ) {
          subscriberFillField = (
            <div className="row justify-content-center">
              <div className="subscriberInput field col-sm-12 col-md-8">
                <input
                  disabled={this.state.show}
                  onChange={this.update('subscriber_id')}
                  type="name"
                  className="form-control"
                  id="name"
                  placeholder={`${t("Enter Subscriber ID")} #*`}
                />
              </div>
            </div>
          );
        } else {
          insuranceFillField = <div className="row justify-content-center"></div>;
        }

        insuranceDiv = (
          <div className="insContainer">
            <div className="insuranceDescription">{t("Do you have dental insurance")}?</div>
            {['No', 'Yes'].map((key) => (
              <Button
                color={this.props.config.color}
                disabled={this.state.show}
                className={`insurance ${
                  key === this.state.hasInsurance ? 'selected' : ''
                }`}
                type="button"
                key={key}
                onClick={this.buttonSelected(key)}
              >
                {t(key)}
              </Button>
            ))}
          </div>
        );
      }
    }

    let providerName = '';

    const appointmentIndex = this.props.config.appointments.findIndex(
      (appointment) =>
        parseInt(appointment.appointment_id) ===
        parseInt(window.sessionStorage.appointmentId),
    );
    const appointment = this.props.config.appointments[appointmentIndex];

    window.sessionStorage.setItem('confirmedAppt', JSON.stringify(appointment));

    if (appointment && appointment.provider_select !== 0) {
      if (providerId && providerId > 0) {
        providerName = t("with {{name}}",{name:provider.name});
      } else {
        providerName = t("at {{practiceName}}",{practiceName:this.props.config.practiceName});
      }
    } else {
      providerName = t("at {{practiceName}}",{practiceName:this.props.config.practiceName});
    }

    // all patients
    questions.forEach((ele, i) => {
      if (ele.type === 1 && ele.condition === 1) {
        questionsPlaces.push(ele);
        topQuestions.push(
          <TextQuestion
            dob={this.state.dob}
            onChange={this.update(`question-${ele.id}`)}
            value={this.state[`${ele}`]}
            refs={`textInput${ele.id}`}
            disabled={this.state.show}
            key={i}
            data={ele}
          />,
        );
      } else if (ele.type === 2 && ele.condition === 1) {
        questionsPlaces.push(ele);
        topQuestions.push(
          <RadioQuestion
            dob={this.state.dob}
            state={this.state}
            onChange={this.selectionChanged(`question-${ele.id}`)}
            key={i}
            data={ele}
          />,
        );
      } else if (ele.type === 3 && ele.condition === 1) {
        questionsPlaces.push(ele);
        topQuestions.push(
          <DropdownQuestion
            dob={this.state.dob}
            onChange={this.dropdownChanged(`question-${ele.id}`)}
            key={i}
            data={ele}
          />,
        );
      }
    });

    var answeredRequired = true;

    questionsPlaces.forEach((el) => {
      if (
        (el.required === 1 && this.state[`question-${el.id}`] === undefined) ||
        (el.required === 1 && this.state[`question-${el.id}`] === '')
      ) {
        if (el.agerestrict !== '') {
          const restrictDob = moment(this.state.dob).format('YYYY-MM-DD');
          if (restrictDob !== 'Invalid date') {
            var a = moment();
            var diff = a.diff(restrictDob, 'years');

            if (diff <= el.agerestrict) {
              if (
                this.state[`question-${el.id}`] === '' ||
                this.state[`question-${el.id}`] === undefined
              ) {
                answeredRequired = false;
              }
            }
          }
        } else {
          answeredRequired = false;
        }
      }
    });

    let ph = phone.replace(/\D/g, '');

    let len = parseInt(ph.length);

    let isEnabled =
      fname.length > 0 &&
      lname.length > 0 &&
      email.length > 0 &&
      dob.length > 0 &&
      len === 10 &&
      answeredRequired;

    if (!this.props.config.hideInsurance) {
      isEnabled = isEnabled && insurance.length > 1 && ins
    }
    const showGuarModal = this.state.showGuarantorModal;
    return (
      <div className="scheduleAppointmentContainer">
        <div
          ref={(el) => {
            this.el = el;
          }}
          className={`blurry row justify-content-center ${showGuarModal ? 'blur' : ''}`}
        >
          <div className="col col-11 schedule_appointment_header">
            <FlexIcon class="scheduleApptImage" icon={appointment.icon} />

            <div className="appointmentHead">
              {
                currentLanguage === 'es' && appointment.name_translated ? appointment.name_translated : appointment.name
              }
            </div>

            <div className="appointmentHeadLength">{appointment.length} {t("minutes")}</div>

            <div className="appointmentTimes">
            {
              t("{{date}}, {{month}} {{day}} at {{time}}",{
                date: t(moment(selectedAppointment.date).format('dddd')),
                month: t(moment(selectedAppointment.date).format('MMMM')),
                day: moment(selectedAppointment.date).format('D'),
                time:moment(selectedAppointment.starttime, 'hh:mm:ss')
                .format('h:mm a')
                .replace(/(a|p)(m)/, '$1.$2.')
              })
            }
            </div>
            <div className="schedApptProvider">{providerName}</div>
          </div>

          {!this.state.loading ? (
            <>
              <div
                id="firstInput"
                ref={(el) => {
                  this.el = el;
                }}
                className="field col-sm-10 col-md-4"
              >
                <input
                  onKeyPress={(event) => this.handleKeyPress('fname', event)}
                  ref="fname"
                  onFocus={this.scrollToFirst}
                  disabled={this.state.show}
                  onChange={this.update('fname')}
                  type="name"
                  className="form-control"
                  id="fname"
                  placeholder={t("Patient First Name")}
                />
              </div>
              <div
                id="lastInput"
                ref={(el) => {
                  this.el = el;
                }}
                className="field col-sm-10 col-md-4"
              >
                <input
                  onKeyPress={(event) => this.handleKeyPress('lname', event)}
                  ref="lname"
                  onFocus={this.scrollToLast}
                  disabled={this.state.show}
                  onChange={this.update('lname')}
                  type="name"
                  className="form-control"
                  id="lname"
                  placeholder={t("Patient Last Name")}
                />
              </div>
              <div
                id="emailInput"
                ref={(el) => {
                  this.el = el;
                }}
                className="field col-sm-10 col-md-8"
              >
                <input
                  onKeyPress={(event) => this.handleKeyPress('email', event)}
                  ref="email"
                  onFocus={this.scrollToEmail}
                  disabled={this.state.show}
                  onChange={this.handleEmail}
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder={t("Email")}
                />
              </div>

              <div className="field col-sm-10 col-md-8">
                <label className="col col-12 label dobLabel" htmlFor="birthdate">
                  {t("Patient Date of Birth")}
                </label>
                <div className="dobDiv">
                  <select
                    onKeyPress={(event) => this.handleKeyPress('month', event)}
                    ref="month"
                    name="date-of-birth-month"
                    id="date-of-birth-month"
                    disabled={this.state.show}
                    onChange={this.handleDob}
                    className={`form-control monthSelect ${
                      this.state.dobAlert ? 'invalid' : ''
                    }`}
                  >
                    <option value="1">{t("January")}</option>
                    <option value="2">{t("February")}</option>
                    <option value="3">{t("March")}</option>
                    <option value="4">{t("April")}</option>
                    <option value="5">{t("May")}</option>
                    <option value="6">{t("June")}</option>
                    <option value="7">{t("July")}</option>
                    <option value="8">{t("August")}</option>
                    <option value="9">{t("September")}</option>
                    <option value="10">{t("October")}</option>
                    <option value="11">{t("November")}</option>
                    <option value="12">{t("December")}</option>
                  </select>

                  <input
                    onKeyPress={(event) => this.handleKeyPress('day', event)}
                    ref="day"
                    type="number"
                    name="date-of-birth-day"
                    id="date-of-birth-day"
                    disabled={this.state.show}
                    onChange={this.handleDob}
                    className={`form-control dayInput ${
                      this.state.dobAlert ? 'invalid' : ''
                    }`}
                    min="1"
                    max="31"
                    placeholder="DD"
                  />
                  <input
                    onKeyPress={(event) => this.handleKeyPress('year', event)}
                    ref="year"
                    type="number"
                    name="date-of-birth-year"
                    id="date-of-birth-year"
                    disabled={this.state.show}
                    onChange={this.handleDob}
                    className={`form-control yearInput ${
                      this.state.dobAlert ? 'invalid' : ''
                    }`}
                    min="1900"
                    max="2020"
                    placeholder="YYYY"
                  />
                </div>
              </div>

              <div
                id="phoneInput"
                ref={(el) => {
                  this.el = el;
                }}
                className="field col-sm-10 col-md-8"
              >
                <input
                  onKeyPress={(event) => this.handleKeyPress('phone', event)}
                  ref="phone"
                  onFocus={this.scrollToPhone}
                  onKeyDown={this.onKeyPress}
                  disabled={this.state.show}
                  value={this.state.phone}
                  onChange={this.handlePhone}
                  className="form-control"
                  placeholder={t("Mobile Number")}
                  name="mobile"
                  id="mobile"
                  autoComplete="mobile tel"
                />
              </div>

              {sendCodeAlert}

              <hr
                className="field col-8"
                style={{
                  color: '#d3d3d3',
                  backgroundColor: '#d3d3d3',
                }}
              />

              {topQuestions}

              {this.state.ccHandleMode && (
                <div className="payment-wrapper">
                  {this.state.ccHandleMode === 'deposit' && (
                    <h6>
                      {
                        currentLanguage === 'es' && this.state.ccDepositTextTranslated ? this.state.ccDepositTextTranslated : this.state.ccDepositText
                      }
                      <br />
                      <br />
                      {t("Deposit Amount")}: ${this.state.ccDepositAmount}
                    </h6>
                  )}
                  {this.state.ccHandleMode === 'hold' &&
                    <h6>
                      {
                        currentLanguage === 'es' && this.state.ccHoldTextTranslated ? this.state.ccHoldTextTranslated : this.state.ccHoldText
                      }
                    </h6>
                  }

                  {this.state.merchant === 'moolah' && (
                    <div
                      className="moolah-payment-iframe"
                      id="moolah-payment-iframe"
                    ></div>
                  )}
                  <Disclaimer
                    syncCardData={this.handleCardDataSync}
                    phone={this.props.config.practicePhone}
                    vendor={this.state.merchant}
                    color={this.props.config.color}
                  />
                </div>
              )}
              {this.props.config.hideInsurance ? (
                <div className="col col-11"></div>
              ): (
                <div className="col col-11 insuranceContainer">
                {insuranceDiv}

                {insuranceSelection}

                {insuranceFillField}

                {subscriberFillField}

                {questionsDiv}

                {insuranceMessage}
              </div>
              )}
              {this.state.guarantor && this.state.scheduleFor === 'someoneElse' ? (
                <div id="guar-sch-btn-container" className="col col-11">
                  <BackButton
                    id="continueButton"
                    color={this.props.config.color}
                    hover={this.state.hover}
                    className="col col-11 scheduleApptButton btn btn-primary"
                    onClick={this.handleShowGuarantorModal}
                  >
                    Back
                  </BackButton>

                  <ContinueButton
                    id="continueButton"
                    color={this.props.config.color}
                    hover={this.state.hover}
                    disabled={!isEnabled || this.state.show}
                    className="col col-11 scheduleApptButton btn btn-primary"
                    onClick={
                      this.state.ccHandleMode && this.state.merchant === 'moolah'
                        ? this.handleMoolahSubmit
                        : this.handleSubmit
                    }
                  >
                    {t("Schedule Appointment")}
                  </ContinueButton>
                </div>
              ) : (
                <ContinueButton
                  id="continueButton"
                  color={this.props.config.color}
                  hover={this.state.hover}
                  disabled={!isEnabled || this.state.show}
                  className="col col-11 scheduleApptButton btn btn-primary"
                  onClick={
                    this.state.ccHandleMode && this.state.merchant === 'moolah'
                      ? this.handleMoolahSubmit
                      : this.handleSubmit
                  }
                >
                  {t("Schedule Appointment")}
                </ContinueButton>
              )}
            </>
          ) : (
            <div className="col col-12 createLoader">
              <Spacer height="10px" />
              <PulseLoader color={'#707070'} size={10} />
            </div>
          )}
        </div>
        <Modal
          onClose={this.showModal}
          show={this.state.show}
          phone={this.state.guarantor ? this.state.guarantor.phone : this.state.phone}
        >
          Message in Modal
        </Modal>
        {this.state.showGuarantorModal && (
          <GuarantorModal
            scheduleFor={this.state.scheduleFor}
            guarantor={this.state.guarantor}
            config={{ ...this.props.config, hover: this.state.hover }}
            changeScheduleFor={this.handleScheduleFor}
            closeGuarantorModal={this.handleCloseGuarantorModal}
          />
        )}
      </div>
    );
  }
}

async function tokenizeCard(cardNumber, expMonth, expYear) {
  let expiry = '' + expYear.toString() + expMonth.toString();
  expiry = Number(expiry);

  try {
    const { data } = await axios.post(
      `https://${
        process.env.REACT_APP_USE_CARDCONNECT_TEST_ENDPOINT === 'true'
          ? 'boltgw-uat'
          : 'boltgw'
      }.cardconnect.com/cardsecure/api/v1/ccn/tokenize`,
      { account: cardNumber, expiry },
    );
    const token = data.token;
    if (!token) {
      throw new Error('Missing token in bolt response');
    }
    return {
      token,
      expiry,
      lastfour: cardNumber.slice(-4),
    };
  } catch (err) {
    console.error(err);
    alert('Card Tokenization Failed. Double check the card number and CVV.');
    return;
  }
}

export default withTranslation()(ScheduleAppointment);

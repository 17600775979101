import React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import './appointment_options.scss';
import { Button as ContinueButton, Spacer } from '../../styles/styledComponents';
import { withTranslation } from 'react-i18next';

class AppointmentOptionsTwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loadingTimes: false,
      hover: JSON.parse(window.sessionStorage.phoneType || true),
      appointments: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleAppointment = this.handleAppointment.bind(this);
    this.handleProvider = this.handleProvider.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingTimes: true });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem('phoneType', true);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem('phoneType', false);
      this.setState({ hover: true });
    }

    const scrolly = document.getElementsByClassName('div2');
    scrolly[0].style.display = 'block';

    const currentPage = window.sessionStorage.getItem('currentPage');

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 6) {
        window.sessionStorage.setItem('currentPage', 7);
        window.sessionStorage.setItem('backPage', 6);
        this.getData();
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(
            `/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ''
            }`,
          );
        } else {
          this.props.history.push(
            `/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ''
            }`,
          );
        }
      }
    } else {
      if (parseInt(currentPage) === 6 || parseInt(currentPage) === 7) {
        window.sessionStorage.setItem('currentPage', 7);
        this.getData();
      } else {
        if (
          window.securedNav === true &&
          (parseInt(currentPage) === 8 || parseInt(currentPage) === 9)
        ) {
          window.sessionStorage.setItem('currentPage', 7);
          window.securedNav = false;
          this.getData();
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(
              `/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ''
              }`,
            );
          } else {
            this.props.history.push(
              `/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ''
              }`,
            );
          }
        }
      }
    }
  }

  getData() {
    const dow = JSON.parse(window.sessionStorage.getItem('dow'));
    const patientPrefs = JSON.parse(window.sessionStorage.getItem('patientPrefs'));
    let data;

    let providerId = window.sessionStorage.getItem('providerId');

    let providerIdx = this.props.config.providers.findIndex(
      (providers) => parseInt(providerId) === parseInt(providers.provider_assoc),
    );
    let foundProvider = this.props.config.providers[providerIdx];

    if (foundProvider) {
      providerId = foundProvider.provider_id;
    }

    if (window.sessionStorage.getItem('locationId')) {
      data = {
        client: window.sessionStorage.getItem('locationId'),
        dow: dow,
        appointmentId: window.sessionStorage.getItem('appointmentId'),
        providerId: providerId,
        patientPrefs: patientPrefs,
      };
    } else {
      data = {
        client: this.props.match.params.id,
        dow: dow,
        appointmentId: window.sessionStorage.getItem('appointmentId'),
        providerId: providerId,
        patientPrefs: patientPrefs,
      };
    }

    this.props.getAppointments(data).then(() => {
      const preferences = JSON.parse(
        window.sessionStorage.getItem('SchedulePreferences'),
      );

      let appointments = this.props.appointments;
      if (appointments === 'NA' || appointments === 400 || appointments === 'none') {
        this.setState({
          loadingTimes: false,
          loaded: true,
          appointments: [],
        });
        return;
      }

      if (
        this.props.appointments !== 'NA' &&
        this.props.appointments !== 400 &&
        this.props.appointments !== 'none'
      ) {
        appointments = [...appointments].reverse();
      }

      let days = {};

      for (let day in preferences) {
        let keys = [];
        let obj = preferences[day];

        for (let key in obj) {
          if (obj[key] === true) {
            keys.push(key);
            days[day] = keys;
          }
        }
      }

      let daysKeys = Object.keys(days);

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];

      let dow = [];

      daysKeys.forEach((day) => {
        if (daysOfWeek.indexOf(day)) {
          dow.push(daysOfWeek.indexOf(day) + 1);
        }
      });

      let arg = [];
      let availableAppointments = [];

      if (
        appointments !== 400 &&
        appointments !== 'NA' &&
        typeof appointments !== 'string'
      ) {
        dow.forEach((day) => {
          let appoints = appointments.filter(function (app) {
            return parseInt(app.dow) === parseInt(day);
          });

          if (appoints.length > 1) {
            arg.push(appoints);
          }
        });

        arg.forEach((appt) => {
          availableAppointments = availableAppointments.concat(appt);
        });
      }

      if (availableAppointments.length < 4) {
        if (parseInt(window.sessionStorage.getItem('backPage')) !== 5) {
          window.sessionStorage.setItem('backPage', 7);
        }

        window.sessionStorage.setItem('currentPage', 5);

        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(
            `/${this.props.match.params.id}/${this.props.match.params.analytics}/8`,
          );
        } else {
          this.props.history.push(`/${this.props.match.params.id}/8`);
        }
      }

      let apps;

      if (window.sessionStorage.getItem('optionsDow')) {
        apps = availableAppointments.filter(function (app) {
          return app.dow !== parseInt(window.sessionStorage.getItem('optionsDow'));
        });
      }

      if (apps) {
        if (apps.length >= 2) {
          availableAppointments = apps;
        }
      }

      let that = this;

      setTimeout(function () {
        that.setState({
          loadingTimes: false,
          loaded: true,
          appointments: appointments,
        });
      }, 400);
    });
  }

  handleProvider(e) {
    e.preventDefault();
    window.sessionStorage.setItem('currentPage', 3);
    window.securedNav = true;
    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(
        `/${this.props.match.params.id}/${this.props.match.params.analytics}/3`,
      );
    } else {
      this.props.history.push(`/${this.props.match.params.id}/3`);
    }
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem('backPage', 7);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(
        `/${this.props.match.params.id}/${this.props.match.params.analytics}/8`,
      );
    } else {
      this.props.history.push(`/${this.props.match.params.id}/8`);
    }
  }

  handleAppointment = (day) => (e) => {
    e.preventDefault();

    window.sessionStorage.setItem('selectedAppointment', JSON.stringify(day));
    window.sessionStorage.setItem('currentPage', 8);
    window.sessionStorage.setItem('backPage', 7);
    window.sessionStorage.setItem('backButton', JSON.stringify(false));

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(
        `/${this.props.match.params.id}/${this.props.match.params.analytics}/9`,
      );
    } else {
      this.props.history.push(`/${this.props.match.params.id}/9`);
    }
  };

  render() {
    const { t } = this.props
    const { loaded, loadingTimes, appointments, hover } = this.state;

    if (!loaded || loadingTimes) {
      return (
        <div className="apptOptionsContainer">
          <div className="appointmentOptionsContainer">
            <div className="apptHeader">{t("No problem")}!</div>

            <div className="apptDescription">{t("Here are some more options")}:</div>

            <div className="row justify-content-center">
              {loadingTimes && (
                <>
                  <Spacer height="10px" />
                  <PulseLoader color={'#707070'} size={10} />
                </>
              )}
            </div>

            <ContinueButton
              color={this.props.config.color}
              hover={hover}
              onClick={this.handleClick}
              className="moreOptions col col-9 btn btn-primary"
            >
              {t("More Options")}
            </ContinueButton>
          </div>
        </div>
      );
    }

    let analyticsParam = '';
    if (this.props.match.params.analytics) {
      analyticsParam = `/${this.props.match.params.analytics}`;
    }
    if (!appointments.length) {
      return <Redirect push to={`/${this.props.match.params.id}${analyticsParam}/8`} />;
    }

    let final = [];

    if (
      appointments !== 400 &&
      appointments !== 'NA' &&
      typeof appointments !== 'string'
    ) {
      final = appointments;
    }

    let changeProvider;
    let provs = [];

    const appointmentIndex = this.props.config.appointments.findIndex(
      (appointment) =>
        parseInt(appointment.appointment_id) ===
        parseInt(window.sessionStorage.getItem('appointmentId')),
    );
    const appointment = this.props.config.appointments[appointmentIndex];

    if (appointment) {
      provs = appointment.providers;

      if (provs.length < 2 || appointment.provider_select === 0) {
        changeProvider = <div></div>;
      } else {
        changeProvider = (
          <ContinueButton
            color={this.props.config.color}
            hover={hover}
            onClick={this.handleProvider}
            className="moreOptions col col-9 btn btn-primary"
          >
            {t("Change Provider")}
          </ContinueButton>
        );
      }
    }

    final = final.slice(3, 6);

    final = final.sort(function (a, b) {
      return a.date.localeCompare(b.date) || a.starttime.localeCompare(b.starttime);
    });

    return (
      <div className="apptOptionsContainer">
        <div className="appointmentOptionsContainer">
          <div className="apptHeader">{t("No problem")}!</div>

          <div className="apptDescription">{t("Here are some more options")}:</div>

          <div className="row justify-content-center margin">
            {final.map((day, index) => {
              return (
                <ContinueButton
                  color={this.props.config.color}
                  hover={hover}
                  key={index}
                  onClick={this.handleAppointment(day)}
                  className="col col-sm-11 col-md-9 appointmentTime"
                >
                  {t("{{date}}, {{month}} {{day}} at {{time}}", {
                    date: t(moment(day.date).format("dddd")),
                    month: t(moment(day.date).format("MMMM")),
                    day: moment(day.date).format("D"),
                    time: moment(day.starttime, "hh:mm:ss")
                      .format("h:mm a")
                      .replace(/(a|p)(m)/, "$1.$2."),
                  })}
                </ContinueButton>
              );
            })}

            <ContinueButton
              color={this.props.config.color}
              hover={hover}
              onClick={this.handleClick}
              className="appointmentTime col col-sm-11 col-md-9"
            >
              {t("Show More Options")}
            </ContinueButton>
          </div>

          {changeProvider}
        </div>
      </div>
    );
  }
}

export default withTranslation()(AppointmentOptionsTwo)

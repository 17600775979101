/**
 * Purpose: Helper Utils for non-Flex-specific purposes
 * Author: George Nobles the Great
 */


// Standard console ops
function $$(...msg) {
  console.log(...msg) // standard
}

// Generate the other console types
;["info", "error", "warn", "table"].forEach(type => {
  $$[type] = (...msg) => console[type](msg )
//   $$.$$[type] = (...msg) => console.log({ type, msg, full: true })
})

/**
 * DESTRUCTURE EXTENSIONS
 */
$$.keys = {
  only: function (obj, keys = []) {
    if (Array.isArray(keys)) {
      return keys.reduce(
        (accum, key) => ({
          ...accum,
          [key]: obj[key]
        }),
        {}
      )
    }
    return Object.entries(keys).reduce(
      (accum, [key, val]) => ({
        ...accum,
        [key]: typeof val === "function" ? val(obj[key]) : obj[key]
      }),
      {}
    )
  },
  except: function (obj, ...keys) {
    return Object.entries(obj).reduce((accum, [k, v]) => {
      if (! keys.includes(k)) {
        accum[k] = v
      }
      return accum
    }, {})
  }
}

/**
 * Eliminate object properties that are either
 * A) lengthy strings or arrays, or
 * B) sub-objects
 * @param {object} obj - The object to reduce
 * @param {object} max - expects arrLen and strLen props
 */
$$.rinse = (obj, max = {}) => {
  ["arrLen", "arrStr"].forEach(v => {
    if (!max[v]) {
      max[v] = 32
    }
  })
  return Object.entries(obj).reduce((accum, [k, v]) => {
    const type = typeof v
    const ref =
      type == "object" ? (Array.isArray(v) ? "arrLen" : false) : type == "string" ? "arrStr" : false
    return !max[ref] || v.length > max[ref] ? accum : { ...accum, [k]: v }
  }, {})
}

 /** easy for x to y loop */
 $$.x2y = (from, to, each) => 
   [...Array(to - from)].map((_, i) => each(i + from))

$$.sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

$$.debug = (tag, ...args) => {
  if(process.env[`DEBUG_${tag}`]){
    console.log(...args)
  }
}

export default $$

import axios from "axios";

export const fetchConfig = clientId => {
  return axios.get(`${process.env.REACT_APP_FLEXAPP}/os/getconfig?c=${clientId}`);
};


export const fetchLocations = clientId => {
  return axios.get(`${process.env.REACT_APP_FLEXAPP}/os/getlinks?c=${clientId}`);
};

import React from "react";
import Select, { components } from 'react-select';
import moment from 'moment';
import i18n from '../../../../lib/i18n/index.js';

const SingleValue = ({ children, ...props }) => (
   <components.SingleValue {...props}>
     {children}
   </components.SingleValue>
 );

class DropdownQuestion extends React.Component {
  render() {
    var style = {marginTop: "10px", marginBottom: "1em"};
    var ageRestrictionShow = true;
    const currentLanguage = i18n.language;

    if (this.props.data.agerestrict !== "") {
      ageRestrictionShow = false;
      const restrictDob = moment(this.props.dob).format('YYYY-MM-DD');
      if (restrictDob !== "Invalid date") {
        var a = moment();
        var diff = a.diff(restrictDob, 'years');
        if (diff <= this.props.data.agerestrict) {
          ageRestrictionShow = true;
        }
      }
    }

    if (this.props.data.condition === 4 || this.props.data.condition === 5 || this.props.data.condition === 6 || this.props.data.condition === 7) {
      style = {
        margin: "auto",
        marginTop: "10px"
      };
    }

    var options = [];

    const answersArray = currentLanguage === 'es' && this.props.data.answers_translated ? this.props.data.answers_translated : this.props.data.answers;

    answersArray.forEach((item, i) => {
      options.push({value: i, label: item});
    });

    var required = this.props.data.required === 1 ? "*" : "";

    return (
      ageRestrictionShow ? <div id="selectInput" className="col-sm-10 col-md-8" style={style}>
        <h6 id="radio-questions-title">
          {currentLanguage === 'es' && this.props.data.question_translated
            ? this.props.data.question_translated
            : this.props.data.question}
          {required}
        </h6>
            <Select maxMenuHeight={225} isClearable={false} isSearchable={false} onChange={this.props.onChange} components={{ SingleValue }} options={options} placeholder={currentLanguage === 'es' ? 'Seleccionar...' : 'Select...'} />
      </div> : <div></div>
    );
  }
}

export default DropdownQuestion;

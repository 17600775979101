import React from "react";
import moment from 'moment';
import i18n from '../../../../lib/i18n/index.js';

class RadioQuestion extends React.Component {
  render() {
    var style = {marginBottom: "1em", "color": "#707070"};
    var ageRestrictionShow = true;
    const currentLanguage = i18n.language;

    if (this.props.data.agerestrict !== "") {
      ageRestrictionShow = false;
      const restrictDob = moment(this.props.dob).format('YYYY-MM-DD');

      if (restrictDob !== "Invalid date") {
        var a = moment();
        var diff = a.diff(restrictDob, 'years');
        if (diff <= this.props.data.agerestrict) {
          ageRestrictionShow = true;
        }
      }
    }

    if (this.props.data.condition === 4 || this.props.data.condition === 5 || this.props.data.condition === 6 || this.props.data.condition === 7) {
      style = {
        margin: "auto",
        marginTop: "10px",
        marginBottom: "1em"
      };
    }

    var required = this.props.data.required === 1 ? "*" : "";

    return (
      ageRestrictionShow ? <div id="radioInput" className="col-sm-10 col-md-8" style={style}>
        <h6 id="radio-questions-title">
          {currentLanguage === 'es' && this.props.data.question_translated
            ? this.props.data.question_translated
            : this.props.data.question}
          {required}
        </h6>
        <div id="radio-questions">
          {(currentLanguage === 'es' && this.props.data.answers_translated
            ? this.props.data.answers_translated
            : this.props.data.answers).map((ele, i) => {
              return (
                <div className="radio" key={i}>
                  <input
                    id="inputRadioButton"
                    type="radio"
                    value={ele}
                    checked={this.props.state[`question-${this.props.data.id}`] === ele}
                    onChange={this.props.onChange}
                    style={{ "marginRight": "10px" }}
                  />
                  <label style={{ color: "#707070" }} id="radioSelectLabel">{ele}</label>
                </div>
              );
          })}
        </div>
      </div> : <div></div>
    );
  }
}

export default RadioQuestion;

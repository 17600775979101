import React, { useState } from 'react';
import './schedule_appointment_modal.scss';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const months = getMonths()
const years = getYears()

function Disclaimer({ syncCardData, vendor, phone}) {
  const [cardNumber, setCardNumber] = useState('');
  const [expMonth, setExpMonth] = useState('-');
  const [expYear, setExpYear]= useState('-');
  const [ccAgree, setAgree] = useState(false);
  const { t } = useTranslation();

  const handleCardNumberChange = (event) => {
    const num = cc_format(event.target.value)
    setCardNumber(num);
    syncCardData({
      cardNumber: num,
      expMonth,
      expYear,
      ccAgree
    })
  };

  const handleExpMonthChange = (event) => {
    setExpMonth(event.target.value);
    syncCardData({
      cardNumber,
      expMonth: event.target.value,
      expYear,
      ccAgree
    })
  };

  const handleExpYearChange = (event) => {
    setExpYear(event.target.value);
    syncCardData({
      cardNumber,
      expMonth,
      expYear: event.target.value,
      ccAgree
    })
  };

  function handleAgreeChange(){
    setAgree(! ccAgree);
    syncCardData({
      cardNumber,
      expMonth,
      expYear,
      ccAgree: ! ccAgree
    })
  };

  return (
    <div>
      <form id="paymentForm">
         {
          vendor === 'cardconnect' &&
          <div className="row" style={{maxWidth:'716px', margin:'0 auto'}}>
              <div className="col-md-12 col-lg-12 col-xl-12 pl-0 pr-0">
                <div className="mb-3">
                  <label htmlFor="cardNum" className="form-label">{t("Card Number")}</label>
                  <input type="text" className="form-control" id="cardNum" minLength="19" maxLength="19" pattern="[0-9]+" value={cardNumber} onChange={handleCardNumberChange} required />
                </div>
              </div>
              <div className="col-lg-4 col-xl-4 pl-2 pr-2">
                <div className="mb-3">
                  <label htmlFor="expMonth" className="form-label">{t("Exp Month")}</label>
                  <select type="text" className="form-control" id="expMonth" value={expMonth} onChange={handleExpMonthChange} required>
                    <option value='-'>-</option>
                    { months.map( month => 
                      <option key={`month-${month}`} value={month}>{month}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-xl-4 pl-0 pr-0">
                <div className="mb-3">
                  <label htmlFor="expYear" className="form-label">{t("Exp Year")}</label>
                  <select type="text" className="form-control" id="expYear" value={expYear} onChange={handleExpYearChange} required>
                    <option value='-'>-</option>
                    { years.map( year => 
                      <option key={`year-${year}`} value={year}>{year}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          }
          <div>
            <p>
                  {t("By entering your credit card information, you are giving permission to our dental practice to securely store your credit card information on file.")}
                </p>
                <p>
                  {t("If you have any questions, please call our practice at")} {phone}.
                </p>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" onChange={handleAgreeChange} id="defaultCheck1" required />
            <label className="form-check-label" htmlFor="defaultCheck1">
              {t("I understand")}
            </label>
          </div>
      </form>
      
    </div>
  )
}


function getMonths(){
  const monthList = []
  for(let month = 1; month <=12; month++){
    monthList.push(month)
  }
  return monthList
}

function getYears(){
  const currentYear = new Date().getFullYear();
  const yearList = []
  for(let year = currentYear; year <= currentYear + 20; year++){
    yearList.push(year)
  }
  return yearList
}

function cc_format(value) {
  const incoming = value.replace(/[^\d]/g, '').substr(0, 16)
  const parts = [];

  for (let i = 0; i < incoming.length; i += 4) {
    parts.push(incoming.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(" ") : value;
}
Disclaimer.propTypes = {
  syncCardData: PropTypes.func,
  vendor: PropTypes.string, 
  phone: PropTypes.string
};

export default Disclaimer;
import React from 'react';
import styled from 'styled-components';
import './locations.scss';
import 'css.escape';
import $ from "jquery";
import { getContrast } from '../../styles/contrast';
import { PulseLoader } from 'react-spinners';

import CSSFilter from '../../styles/css-filter';
import { hexToRgb } from '../../styles/css-filter';

import FlexIcon from '../../styles/flex_icon';
import { withTranslation } from 'react-i18next';

class Locations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      hover: true
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = id => e => {
    e.preventDefault();

    window.sessionStorage.setItem("currentPage", 0);
    window.sessionStorage.setItem("locationId", id);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/1`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/1`);
    }
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = "flex";

    localStorage.clear();
    window.sessionStorage.removeItem("config");
    window.sessionStorage.removeItem("providerId");
    window.sessionStorage.removeItem("patientType");
    window.sessionStorage.removeItem("backButton");
    window.sessionStorage.removeItem("currentPage");
    window.sessionStorage.removeItem("provider");
    window.sessionStorage.removeItem("locationId");
    window.sessionStorage.removeItem("backPage");
    window.sessionStorage.removeItem("firstAvailable");
    window.sessionStorage.removeItem("cacheDays");
    window.sessionStorage.removeItem("SchedulePreferences");
    window.sessionStorage.removeItem("patientPrefs");
    window.sessionStorage.removeItem("appointments");
    window.sessionStorage.removeItem("optionsDow");
    window.sessionStorage.removeItem("dow");
    window.sessionStorage.removeItem("phoneType");
    window.sessionStorage.removeItem("iconColor");
    window.sessionStorage.removeItem("backSkip");
    window.sessionStorage.removeItem("liineSessionId")

    window.sessionStorage.setItem("currentPage", 0);
    window.sessionStorage.setItem("backPage", 0);
    window.sessionStorage.setItem("currentPage", 0);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem("phoneType", false);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem("phoneType", true);
      this.setState({ hover: true });
    }

    //set liine session id if present
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('liine_session_id')) {
      window.sessionStorage.setItem("liineSessionId", queryParams.get('liine_session_id'))
    }

    const backButton = document.getElementsByClassName("backButton");
    $(backButton).addClass("hideButton");

    const div2 = document.getElementsByClassName("div2");
    $(div2).attr('style', 'overflow-y: scroll !important');

    this.props.getConfig(this.props.match.params.id)
      .then(() => {
        if (this.props.config.mulitiste === 0 || this.props.config.mulitiste === null) {
          if (this.props.match.params.analytics !== 'undefined' && this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/1`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/1`);
          }
        } else {
          this.props.getLocations(this.props.match.params.id)
            .then(() => {

              if (this.props.config.color) {
                const rgb = hexToRgb(this.props.config.color);

                const color = new CSSFilter.Color(rgb[0], rgb[1], rgb[2]);
                const solver = new CSSFilter.Solver(color);
                const result = solver.solve();

                if (result.loss > 30) {
                  window.location.reload();
                } else {
                  window.sessionStorage.setItem("iconColor", JSON.stringify(result.filter));

                  this.setState({
                    loaded: true
                  });
                }
              } else {
                this.setState({
                  loaded: true
                });
              }

            });
        }
      });
  }

  render() {
    const { t } = this.props;

    const contrastColor = this.props.config.color ? this.props.config.color : "#fffff";
    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    if (!this.state.loaded) {
      return <div className="optionsTwoLoader">
        <Spacer height="10px" />
        <PulseLoader color={"#707070"} size={10} />
      </div>;
    }

    const Button = styled.button`
      background-color: white !important;
      color: #707070 !important;
      border: 1px solid ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }

      &:focus {
        border: 2px solid #2699fb !important;
        outline: none !important;
      }
    `;

    let locations = [];

    locations = (this.props.locations !== 404 && this.props.locations !== 500 && this.props.locations !== "NA" ? this.props.locations : []);

    let locationsHeader;

    if (locations.length < 1) {
      locationsHeader = <div>
        <div className="row justify-content-center">
          <div className="col col-12 locationHeader">
            {t('Not Available')}
          </div>
          <div className="col col-11 locationsDescription">
            {t("We're not currently accepting online appointments. Please give us a call instead!")}
          </div>
        </div>
      </div>;
    } else {
      locationsHeader = <div>
        <div className="row justify-content-center">
          <FlexIcon height={"35"} width={"35"} class="scheduleApptImage" icon={10} />
          <div className="col col-12 locationHeader">
            {t("Choose Location")}
          </div>
          <div className="col col-11 locationsDescription">
            {t('Choose the location that works best for you.')}
          </div>
        </div>
      </div>;
    }

    const divStyle = (src) => ({
      backgroundImage: 'url(' + CSS.escape(src) + ')',
      margin: '0px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: "cover"
    });

    return (
      <div className="locationsList">
        {locationsHeader}

        <div className="row locationPad justify-content-center">

          {locations.map((location, index) => {

            return (
              <div key={index} style={divStyle(location.image_url)} className="col-sm-5 location">
                <Button onClick={this.handleClick(location.os_id)} className="col col-12 btn btn-primary locationButton">{location.practice_name}</Button>
              </div>
            );
          })}
        </div>
      </div>
    )
  }
}

export default withTranslation()(Locations)

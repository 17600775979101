import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

let intervalId
const RedirectUrl = ({url}) => {
  const [seconds, setSeconds] = useState(5);
  const { t } = useTranslation();

  const ticker = () => {
    if (seconds > 0) {
      setSeconds(seconds - 1)
    } else {
      clearInterval(intervalId)
    }
  };

  const useTicker = useCallback(ticker, [seconds])

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalId);
      window.location.assign(url)
    }
    return () => clearInterval(intervalId);
  }, [seconds, url])

  useEffect(() => {
    intervalId = setInterval(useTicker, 1000);
    return () => clearInterval(intervalId);
  }, [useTicker, seconds]);
  
  return (
    <div className="timer">
      <p>{t('You will be redirected to')}{' '}
        <a href={url}>{url}</a>{' '}
        {seconds === 0
          ? t('now')
          : t('in')}{' '}
        {seconds}{' '}
        {t(seconds === 1 ? 'second' : 'seconds')}.
      </p>
    </div>
  );
};

export default RedirectUrl;

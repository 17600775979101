import React from 'react';
import styled from 'styled-components';
import './schedule_preferences.scss';
import sunrise from '../../../images/sunrise.svg';
import sunFull from '../../../images/sun-full.svg';
import moon from '../../../images/moon.svg';
import { PulseLoader } from 'react-spinners';
import { getContrast } from '../../styles/contrast';
import FlexIcon from '../../styles/flex_icon';
import { withTranslation } from 'react-i18next';

const days = [{ 1: "Sunday" }, { 2: "Monday" }, { 3: "Tuesday" }, { 4: "Wednesday" }, { 5: "Thursday" }, { 6: "Friday" }, { 7: "Saturday" }];

const selectedTimes = {};

days.forEach(day => {
  selectedTimes[Object.values(day)] = {
    morning: false,
    afternoon: false,
    evening: false,
    imagesLoaded: false
  };
});

class SchedulePreferences extends React.Component {
  constructor(props) {
    super(props);


    this.state = selectedTimes;

    this.handleClick = this.handleClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount() {

    this.setState({
      hover: JSON.parse(window.sessionStorage.phoneType || true)
    })

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem("phoneType", true);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem("phoneType", false);
      this.setState({ hover: true });
    }

    if (window.sessionStorage.cacheDays) {
      this.setState(JSON.parse(window.sessionStorage.cacheDays))
    };

    const currentPage = window.sessionStorage.currentPage;

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 4) {
        window.sessionStorage.setItem("currentPage", 5);
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if (parseInt(currentPage) === 4 || parseInt(currentPage) === 5) {
        window.sessionStorage.setItem("currentPage", 4);
      } else {
        if (window.securedNav === true && parseInt(currentPage) === 6) {
          window.sessionStorage.setItem("currentPage", 5);
        } else {
          if (this.props.match.params.analytics !== undefined) {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
            } else {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) - 1}`);
            }
          } else {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/`);
            } else {
              this.props.history.replace(`/${this.props.match.params.id}/${parseInt(currentPage) - 1}`);
            }
          }
        }
      }

      window.securedNav = false;
    }

    this.setState({ imagesLoaded: true });
  }

  toggleTime(day, time) {
    this.setState({
      [day]: { ...this.state[day], [time]: !this.state[day][time] }
    })
  }

  // handles selected preference times and days caching
  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem("SchedulePreferences", JSON.stringify(this.state));

    let days = {};

    for (let day in this.state) {
      let keys = [];
      let obj = this.state[day];

      for (let key in obj) {

        if (obj[key] === true) {
          keys.push(key);
          days[day] = keys;
        }
      }
    }

    let cacheDays = {};

    for (let day in this.state) {
      let keys = {};
      let obj = this.state[day];

      for (let key in obj) {

        if (obj[key] === true) {
          keys[key] = true;
          cacheDays[day] = keys;
        } else {
          keys[key] = false;
          cacheDays[day] = keys;
        }
      }
    }

    window.sessionStorage.setItem("cacheDays", JSON.stringify(cacheDays));


    let daysKeys = Object.keys(days);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let dow = [];
    let patientPrefs = {};

    daysKeys.forEach(day => {
        const dayIndex = daysOfWeek.indexOf(day);
        if (dayIndex >= 0) {
            dow.push(dayIndex + 1);

            days[day].forEach(time => {
                if (days[day].length >= 2) {
                    if (days[day].includes("morning") && days[day].includes("afternoon") && days[day].includes("evening")) {
                        patientPrefs[dayIndex + 1] = { minTime: "08:00", maxTime: "23:00" };
                    } else if (days[day].includes("morning") && days[day].includes("afternoon")) {
                        patientPrefs[dayIndex + 1] = { minTime: "08:00", maxTime: "17:30" };
                    } else if (days[day].includes("afternoon") && days[day].includes("evening")) {
                        patientPrefs[dayIndex + 1] = { minTime: "12:00", maxTime: "23:00" };
                    } else {
                        patientPrefs[dayIndex + 1] = { minTime: "08:00", maxTime: "12:30", minTimeTwo: "17:30", maxTimeTwo: "23:00" };
                    }
                } else {
                    if (time === "morning") {
                        patientPrefs[dayIndex + 1] = { minTime: "08:00", maxTime: "12:00" };
                    } else if (time === "afternoon") {
                        patientPrefs[dayIndex + 1] = { minTime: "12:00", maxTime: "17:30" };
                    } else {
                        patientPrefs[dayIndex + 1] = { minTime: "17:30", maxTime: "23:00" };
                    }
                }
            });
        }
    });

    window.sessionStorage.setItem("patientPrefs", JSON.stringify(patientPrefs));
    window.sessionStorage.setItem("dow", JSON.stringify(dow));
    window.sessionStorage.setItem("currentPage", 5);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/6`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/6`);
    }
  }


  // url handling for back navigation
  handleGoBack(e) {
    e.preventDefault();
    window.sessionStorage.setItem("currentPage", 0);
    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/`);
    }
  }

  render() {
    const { t } = this.props;
    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    if (!this.state.imagesLoaded) {
      return <div className="optionsTwoLoader">
        <Spacer height="10px" />
        <PulseLoader color={"#707070"} size={10} />
      </div>;
    }

    const Button = styled.button`
      background-color: white !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      color: #707070 !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }

      &:focus {
        border-color: #2699fb !important;
        outline: none !important;
      }
    `;

    let daysss = {};
    let ContinueButton;

    for (let day in this.state) {
      let keys = [];
      let obj = this.state[day];

      for (let key in obj) {
        if (obj[key] === true && key !== "hover") {
          keys.push(key);
          daysss[day] = keys;
        }
      }
    }

    if (Object.keys(daysss).length === 0 && daysss.constructor === Object) {
      ContinueButton = <Button disabled={true} className="btn btn-primary continue_button" onClick={this.handleClick}>{t("Continue")}</Button>;
    } else {
      ContinueButton = <Button className="btn btn-primary continue_button" onClick={this.handleClick}>{t("Continue")}</Button>;
    }

    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
    const appointment = this.props.config.appointments[appointmentIndex];

    let allowed_days = [];
    let config_allowed_days = [];
    let firstPass = []
    let final = [];

    if (appointment) {
      allowed_days = appointment.allowed_days;

      config_allowed_days = this.props.config.allowed_days ? this.props.config.allowed_days : [];


      allowed_days.forEach(appt => {
        config_allowed_days.forEach(day => {
          if (parseInt(appt) === parseInt(day)) {
            firstPass.push(day);
          }
        })
      })
    }

    //provider select is off
    if (parseInt(window.sessionStorage.providerId) === 0) {

      appointment.providers.forEach(aptProv => {
        let providerIdx = this.props.config.providers.findIndex(providers => parseInt(aptProv) === parseInt(providers.provider_assoc))
        let foundProvider = this.props.config.providers[providerIdx];
        if (foundProvider) {
          foundProvider.allowed_days.forEach(prov => {
            firstPass.forEach(day => {
              if (parseInt(prov) === parseInt(day)) {
                final.push(day);
              }
            })
          })
        }
      })
    } else {
      if (window.sessionStorage.providerId) {
        let providerIdx = this.props.config.providers.findIndex(providers => parseInt(window.sessionStorage.providerId) === parseInt(providers.provider_assoc))
        let foundProvider = this.props.config.providers[providerIdx];

        foundProvider.allowed_days.forEach(prov => {
          firstPass.forEach(day => {
            if (parseInt(prov) === parseInt(day)) {
              final.push(day);
            }
          })
        })
      }
    }

    const days = [{ 1: "Sunday" }, { 2: "Monday" }, { 3: "Tuesday" }, { 4: "Wednesday" }, { 5: "Thursday" }, { 6: "Friday" }, { 7: "Saturday" }];
    // const days = [{ 1: t("Sunday") }, { 2: t("Monday") }, { 3: t("Tuesday") }, { 4: t("Wednesday") }, { 5: t("Thursday") }, { 6: t("Friday") }, { 7: t("Saturday") }];
    let newDays = days.filter(function (day) {
      if (final.includes(parseInt(Object.keys(day)))) {
        if (parseInt(Object.keys(day)) === 1 || parseInt(Object.keys(day)) === 7) {
          return day;
        } else {
          return day;
        }
      } else if (!final.includes(parseInt(Object.keys(day)))) {
        if (parseInt(Object.keys(day)) === 1 || parseInt(Object.keys(day)) === 7) {
          return null;
        } else {
          return day;
        }
      } else {
        return null;
      }
    });

    let scheduleContainer;

    if (final.length < 1) {
      scheduleContainer = <div>
        <div className="prefHeader">
          {t("Sorry about that!")}
        </div>
        <div className="locationsDescription">
          {t("This appointment is currently not accepting online scheduling. Please call {{ phone }} or click below to change your selections.", { phone: this.props.config.practicePhone })}
        </div>

        <Button className="btn btn-primary continue_button" onClick={this.handleGoBack}>{t("Change Selections")}</Button>
      </div>;
    } else {
      scheduleContainer =
        <div>
          <div className="prefHeader">
            {t("Select the times that might work best:")}
          </div>
          <div className="schedule_container">
            <div className="row justify-content-center">
              {newDays.map(day => final.includes(parseInt(Object.keys(day))) ? (
                <div key={Object.keys(day)} className="col col-12 col-md-5 day_container">
                  <div className="row justify-content-center">
                    <div className="col col-6 day_name">
                      {t(Object.values(day))}
                    </div>

                    <div className="col col-12 day_times">
                      <button onClick={() => this.toggleTime(Object.values(day), 'morning')} className={`day morning ${this.state[Object.values(day)].morning}`}><FlexIcon height={"20"} class="dayButton sunrise" icon={12} /><span className="morningText">{t("Morning")}</span></button>
                      <button onClick={() => this.toggleTime(Object.values(day), 'afternoon')} className={`day morning ${this.state[Object.values(day)].afternoon}`}><FlexIcon height={"25"} class="dayButton" icon={13} />{t("Afternoon")}</button>
                      <button onClick={() => this.toggleTime(Object.values(day), 'evening')} className={`day morning ${this.state[Object.values(day)].evening}`}><FlexIcon height={"18"} class="dayButton moon" icon={14} /><span className="eveningText">{t("Evening")}</span></button>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={Object.keys(day)} className="col col-12 col-md-5 day_container">
                  <div className="row justify-content-center">
                    <div className="col col-6 day_name">
                    {t(Object.values(day))}
                    </div>

                    <div className="col col-12 day_times">
                      <button className={`disabled day morning`}><img height="20" className="dayButton" src={sunrise} alt="sunrise" /><span className="morningText">N/A</span></button>
                      <button className={`disabled day morning`}><img height="25" className="dayButton" src={sunFull} alt="fullsun" />N/A</button>
                      <button className={`disabled day morning`}><img height="18" className="dayButton moon" src={moon} alt="moon" /><span className="eveningText">N/A</span></button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {ContinueButton}
          </div>
        </div>;
    }

    return (
      <div className="schedContainer row justify-content-center">
        <style dangerouslySetInnerHTML={{
          __html: `
        day_times, .true {
          background-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
        }
      `}} />
        <div className="schedInnerContainer col col-12">
          {scheduleContainer}
        </div>
      </div>
    )
  }
}

export default withTranslation()(SchedulePreferences)

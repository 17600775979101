import React from "react";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../lib/i18n/index.js';

class TextQuestion extends React.Component {
  render() {
    const { t } = this.props;
    const currentLanguage = i18n.language;
    var style = {marginBottom: "1em"};
    var ageRestrictionShow = true;

    if (this.props.data.agerestrict !== "") {
      ageRestrictionShow = false;
      const restrictDob = moment(this.props.dob).format('YYYY-MM-DD');
      if (restrictDob !== "Invalid date") {
        var a = moment();
        var diff = a.diff(restrictDob, 'years');
        if (diff <= this.props.data.agerestrict) {
          ageRestrictionShow = true;
        }
      }
    }

    if (this.props.data.condition === 4 || this.props.data.condition === 5 || this.props.data.condition === 6 || this.props.data.condition === 7) {
      style = {
        margin: "auto",
        marginTop: "10px",
        marginBottom: "1em"
      };
    }

    var required = this.props.data.required === 1 ? "*" : "";

    return (
      ageRestrictionShow ? <div id="textInput" className="col-sm-10 col-md-8" style={style}>
        <h6 id="radio-questions-title">
          {currentLanguage === 'es' && this.props.data.question_translated
            ? this.props.data.question_translated
            : this.props.data.question}
          {required}
        </h6>
        <input maxLength="200" ref={this.props.refs} disabled={this.props.disabled} value={this.props.value} onChange={this.props.onChange} className="form-control" placeholder={t("Answer")} name='textInput' id={`textInput${this.props.data.id}`} autoComplete='text'/>
      </div> : <div></div>
    );
  }
}

export default withTranslation()(TextQuestion);

import styled from 'styled-components';
import { getContrast } from './contrast';

function getColor(color) {
  return color ? color : "#2699fb"
}
export const Spacer = styled.div`
  width: ${props => props.width || 0};
  height: ${props => props.height || 0};
`;

export const Button = styled.button`
  border: 1px solid ${props => getColor(props.color)} !important;
  background-color: white !important;
  color: #707070 !important;

  &:hover {
    background-color: ${props => props.hover ? props.color : "white"} !important;
    color: ${props => props.hover ? getContrast(getColor(props.color)) : "#707070" } !important;
  }

  &:focus {
    border-color: #2699fb !important;
    outline: none !important;
  }
`;

export const ScheduleButton = styled.button`
  border-color: ${props => getColor(props.color)} !important;
  background-color: ${props => getColor(props.color)} !important;
  color: ${props => getContrast(getColor(props.color))} !important;
  font-weight: bold !important;
  flex: 1;
  &:hover {
    border-color: ${props => getColor(props.color)} !important;
    background-color: ${props => props.hover ? props.color : "white"} !important;
    color: ${props => getContrast(getColor(props.color))} !important;
  }

  &:focus {
    border-color: #2699fb !important;
    outline: none !important;
  }
`

export const SelectButton = styled.button`
  background-color: white !important;
  color: #707070 !important;
  border-color: ${props => getColor(props.color)} !important;
  border: 1px solid ${props => getColor(props.color)};

  &:hover {
    background-color: ${props => getColor(props.color)} !important;
    color: ${props => getContrast(getColor(props.color))} !important;
    border-color: ${props => getColor(props.color)} !important;
  }

  &.selected {
    background-color: ${props => getColor(props.color)} !important;
    color: ${props => getContrast(getColor(props.color))} !important;
    border-color: ${props => getColor(props.color)} !important;
  }

  &:focus {
    border-color: #2699fb !important;
    outline: none !important;
  }
`

export const BackButton = styled.button`
  border-color: ${props => getColor(props.color)} !important;
  background-color: white !important;
  color: #707070 !important;
  font-weight: bold !important;
  max-width: 150px;
  margin-right: 1rem;
  &:hover {
    background-color: ${props => getColor(props.color)} !important;
    color: ${props => getContrast(getColor(props.color))} !important;
    border-color: ${props => getColor(props.color)} !important;
  }
  &:focus {
    border-color: #2699fb !important;
    outline: none !important;
  }
`
import React from 'react';
import './schedule_appointment.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
// title = "Patient" || "Your"
function PersonInfoForm({title, person, updatePerson, handleKeyUp, disabled=null, invalids}) {
  const { t } = useTranslation();

  return (
    <>
      <fieldset className="field-split form-field">
        <div id="firstInput" className="col-sm-12 col-md-5 col-lg-4">
          <input onChange={updatePerson} name="fname" disabled={disabled} type="name" className={`form-control ${invalids.fname && 'invalid'}`} id="fname" placeholder={`${t(title)} ${t('First Name')}`} value={person.fname} />
        </div>
        <div id="lastInput" className="col-sm-12 col-md-5 col-lg-4">
          <input onChange={updatePerson}  name="lname" disabled={disabled} type="name" className={`form-control ${invalids.lname && 'invalid'}`} id="lname" placeholder={`${t(title)} ${t('Last Name')}`} value={person.lname} />
        </div>
      </fieldset>
      <div className="form-field col-sm-12 col-md-10 col-lg-8">
        <label className="col col-12 label dobLabel" htmlFor="birthdate">{`${t(title)} ${t('Date of Birth')}`}</label>
        <div className="dobDiv">
          <select onChange={updatePerson} name='month' id="month" disabled={disabled} className={`form-control monthSelect ${invalids.month && 'invalid'}`} defaultValue={person.dob.month}>
            {MONTHS.map((m, i) => <option value={i + 1} key={i}>{t(m)}</option>)}
          </select>

          <input onChange={updatePerson} type="number" name="day" id="day" disabled={disabled} className={`form-control dayInput ${invalids.day && 'invalid'}`} min="1" max="31" placeholder="DD" value={person.dob.day}/>
          <input onChange={updatePerson}  type="number" name="year" id="year" disabled={disabled} className={`form-control yearInput ${invalids.year && 'invalid'}`} min="1900" max="2020" placeholder="YYYY" value={person.dob.year}/>
        </div>
      </div>
      <div id="emailInput"  className="form-field col-sm-12 col-md-10 col-lg-8">
        <input onChange={updatePerson} name="email" disabled={disabled} type="email" className={`form-control ${invalids.email && 'invalid'}`} id="email" placeholder={`${t(title)} ${t('Email')}`} value={person.email}/>
      </div>
      <div id="phoneInput" className="form-field col-sm-12 col-md-10 col-lg-8">
        <input onChange={updatePerson} disabled={disabled} value={person.phone} className={`form-control ${invalids.email && 'invalid'}`} placeholder={`${t(title)} ${t('Mobile Number')}`} name='mobile' id='mobile' autoComplete='mobile tel'/>
      </div>
    </>
  )
}

export default (PersonInfoForm)
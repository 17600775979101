import React, { useState, useEffect} from 'react';
import './schedule_appointment_modal.scss';
import moment from 'moment';
import PersonInfoForm from './personInfoForm';
import { ScheduleButton as Button, SelectButton } from '../../styles/styledComponents'
import { useTranslation } from 'react-i18next';

function getIncomingInfo(info) {
  const guarantor = {
    isGuarantor: '',
    fname: '',
    lname: '',
    dob: {
      day: '',
      month: '01',
      year: ''
    },
    phone: '',
    email: ''
  }
  if (!info) {
    return guarantor
  } else {
    const [year, month, day] = info.dob.split('-')
    return {
      ...info,
      isGuarantor: info.isGuarantor ? "yes" : "no",
      phone: formatPhone(info.phone, false),
      dob: {
        day,
        month,
        year
      }
    }
  }
}

function formatPhone(phone, remove) {
  // check if incoming
  let incoming = phone.replace(/[^\d]/g, '');

  phone = incoming.replace(/\D/g,'').replace(/^1/,'').substring(0,10);
  let len = parseInt(phone.length);

  if (len < 3) {
      phone = '(' + phone;
  } else if (len === 3){
      phone = '(' + phone + (remove ? '' : ') ');
  } else if (len < 6) {
      phone = '(' + phone.substring(0,3) + ') ' + phone.substring(3,6);
  } else if (len === 6) {
      phone = '(' + phone.substring(0,3) + ') ' + phone.substring(3,6) +  (remove ? '' : '-');
  } else {
      phone = '(' + phone.substring(0,3) + ') ' + phone.substring(3,6) + '-' + phone.substring(6,10);
  }
  return phone
}

function GuarantorModal({ scheduleFor, guarantor, changeScheduleFor, closeGuarantorModal, config }) {
  const [showForm, setShowForm] = useState(!!scheduleFor)
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [person, setPerson] = useState(getIncomingInfo(guarantor))
  const [formattedDob, setFormattedDob] = useState(guarantor?.dob || '')
  const [formattedPhone, setFormattedPhone] = useState(guarantor?.phone || '')
  const { t } = useTranslation();

  const [invalids, setInvalids] = useState({
    fname: false,
    lname: false,
    email: false,
    phone: false,
    day: false,
    month: false,
    year: false
  })

  useEffect(() => {
    if (person.fname && person.lname && person.email && person.isGuarantor && formattedDob && formattedPhone) {
      setSubmitEnabled(true)
    } else {
      submitEnabled && setSubmitEnabled(false)
    }

  }, [person, formattedDob, formattedPhone, submitEnabled])

  function handlePersonUpdate(input) {
    const {name, value} = input.target
    switch (name) {
      case 'mobile':
        const removeNum = (person.phone.length > value.length)
        handlePhone(value, removeNum)
        break;
      case 'month':
      case 'day':
      case 'year':
        handleDOB(name, value)
        break;
      case 'fname':
      case 'lname':
      case 'email':
        handleTextInput(name, value)
        break;
      default:
        console.error("Unknown form input")
    }
  }

  function handlePhone(phone, remove) {
    phone = formatPhone(phone, remove)
    setPerson({
      ...person,
      phone
    })
    if (phone.length === 14) {
      setFormattedPhone(phone)

    } else {
      formattedPhone && setFormattedPhone('')
    }
  }

  function handleDOB(name, value){
    let {month, day, year} = person.dob
    switch (name) {
      case 'year':
        if (String(value).length > 4) {
          return
        }
        year = handleYear(value)
        break;
      case 'day':
        day = handleDay(value)
        break;
      case 'month':
        month = value
        break;
      default:
        console.error('Unknown DOB input')
    }
    setPerson({
      ...person,
      dob: {
        month,
        day,
        year
      }
    })
    const dobVal = formatDOB({month, day, year})
    if (dobVal) {
      setFormattedDob(dobVal)
    } else {
      formattedDob && setFormattedDob('')
    }
  }

  function handleTextInput(name, value) {
    setPerson({
      ...person,
      [name]: value
    })
  }

  function formatDOB(dobObject) {
    let {day, month, year} = dobObject
    const dateObject = moment(`${year}-${month}-${day}`, ['YYYY-M-D', 'YYYY-MM-DD'], true)
    if (dateObject.isValid() && dateObject.isBefore()) {
      setInvalids({
        ...invalids,
        day: false,
        month: false,
        year: false
      })
      return dateObject.format('YYYY-MM-DD')
    } else {
      setInvalids({
        ...invalids,
        day: true,
        month: true,
        year: true
      })
      return ''
    }
  }

  function handleScheduleSomeoneElse(event){
    changeScheduleFor('someoneElse')
    setShowForm(true)
  };

  function handleScheduleMe(){
    changeScheduleFor('myself')
  };

  function handleIsGuarantor(e) {
    setPerson({
      ...person,
      isGuarantor: e.target.value
    })
  }

  function handleGuarantorSubmit(e) {
    e.preventDefault()
    const formattedPerson = {
      ...person,
      dob: formattedDob,
      phone: formattedPhone,
      isGuarantor: person.isGuarantor === 'yes'
    }
    closeGuarantorModal(formattedPerson)
  }

  
  return (
    <div id="scheduler-modal">
      {showForm ? (
        <div className='row justify-content-center'>
          <div className='form-container'>
            <header className='alert-banner'>
              <h6>{t("Your information")}</h6>
              <div className="alert-banner" role="alert">
                <span className='i-container'>
                  <i>&#8505;</i>
                </span>
                <p>{t("Please enter the information for the person scheduling the appointment. Patient information will be entered on the next screen")}</p>
              </div>
            </header>
            <form id="guarantorForm">
              <div className='row justify-content-center'>

                <div id="isGuarantor" className="form-field col-sm-12 col-md-10 col-lg-8">
                  <label htmlFor="guarantor-question">{t("Are you the parent or legal guardian of the patient?")}</label>
                  <fieldset id="guarantor-question">
                    <label>
                      <input id="yes" value="yes" name="isGuarantor" type="radio" checked={person.isGuarantor === "yes"} onChange={handleIsGuarantor} />
                      {t("Yes")}
                    </label>
                    <label htmlFor="no">
                    <input id="no" name="isGuarantor" value="no" type="radio" checked={person.isGuarantor === "no"} onChange={handleIsGuarantor} />
                      {t("No")}
                    </label>
                  </fieldset>
                </div>
                <PersonInfoForm title="Your" person={person} updatePerson={handlePersonUpdate} invalids={invalids}/>
                <div id="guarantor-submit-button" className="form-field col-sm-12 col-md-10 col-lg-8">
                  <Button id="continueButton" disabled={!submitEnabled} color={config.color} hover={config.hover} className="col btn btn-primary" onClick={handleGuarantorSubmit}>{t("Next")}</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className='opener form-container'>
            <h6>
              {t("Are you scheduling this appointment for you, or someone else?")}
            </h6>
            <div className="scheduler-buttons row justify-content-center">
              <SelectButton color={config.color} hover={config.hover} className="col col-8 btn btn-primary" onClick={handleScheduleMe}>{t("Scheduling for me")}</SelectButton>
              <SelectButton color={config.color} hover={config.hover} className="col col-8 btn btn-primary" onClick={handleScheduleSomeoneElse}>{t("Scheduling for someone else")}</SelectButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function handleYear(year) {
  const currentYear = new Date().getFullYear();
  if (year && year.length >=4 && year < 1900) year = 1900
  if (year && year.length >= 4 && year > currentYear) year = currentYear
  
  return year
}

function handleDay(day) {
  if (!day) {
    return ''
  }
  if (day.length > 2) {
    return day.slice(0, 2)
  }
  if (+day === 0) {
    return 0
  }
  if (+day > 31) {
    return 31
  } else {
    return +day
  }
}

export default GuarantorModal;